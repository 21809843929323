<div class="col-md-12">
  <div class="col-md-12" id="dv-login-wrapper">
    <div class="col-md-12" id="dv-login-content" #div>
      <div class="col-md-12">
        <h6 class="text-danger text-center mb-3 col-sm-9 col-md-7 col-lg-5 mx-auto ua-error-msg" >
          {{customError}}
        </h6>
        <h6 *ngIf="this.nonAdAccount" class="text-danger text-center mb-3 col-sm-9 col-md-7 col-lg-5 mx-auto ua-error-msg">
          Login using your AD credentials associated with your Ascend Learning email.
        </h6>
    </div>
  </div>
</div>
