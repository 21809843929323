import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthService } from '@core/auth.service';
import { LoadNotificationInterceptor } from '@core/load-notification.interceptor';
import { LoadNotificationService } from '@core/load-notification.service';

import { AppComponent } from './app.component';
import { AppRoutingModule } from "./app.routing";
//import { CommonModule, NgLocalization, NgLocaleLocalization } from '@angular/common';
//import { RouterModule } from '@angular/router';
import { HeaderComponent } from '@shared/header/header.component';
import { LoadNotificationComponent } from '@shared/load-notification/load-notification.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { ApiModule } from './api.module';
import { Configuration, ConfigurationParameters } from './configuration';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { StatsComponent } from './stats/stats.component';
import { ToastNotificationComponent } from '@shared/toast-notification/toast-notification.component';
import { ToastNotificationService } from '@core/toast-notification.service';
import { SharedModule } from '@shared/shared.module';
import { CacheInterceptor } from '@core/cache.interceptor';
import { CacheMapService } from '@core/cache-map.service';

// import { NgxDropzoneModule } from 'ngx-dropzone';
// import { DndModule } from 'ngx-drag-drop';

import { NgxPermissionsModule } from 'ngx-permissions';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '@core/user.service';
import { BuConfigService } from '@core/bu-config.service';
import { CustomHeader } from '@core/custom-header';

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    // set configuration parameters here.
    basePath: ""
  }
  return new Configuration(params);
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,


    HomeComponent,
    StatsComponent,
    HeaderComponent,
    LoadNotificationComponent,
    ToastNotificationComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ApiModule.forRoot(apiConfigFactory),

    NgbModule,
    // NgxDropzoneModule,
    // DndModule,

    AppRoutingModule,

    FormsModule,
    ReactiveFormsModule,

    HttpClientModule,
    SharedModule,
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    AuthService,
    LoadNotificationService,
    ToastNotificationService,
    { provide: HTTP_INTERCEPTORS, useClass: LoadNotificationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CustomHeader, multi: true },
    CacheMapService, { provide: Cache, useClass: CacheMapService },
    //{ provide: NgLocalization, useClass: NgLocaleLocalization }
    CookieService,
    BuConfigService,
    UserService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

declare global {
  interface Navigator {
      msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}
