/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MainMenu } from './mainMenu';


export interface Menu { 
    role?: string | null;
    permissions?: Array<string> | null;
    beneathRoles?: Array<string> | null;
    mainMenu?: Array<MainMenu> | null;
}

