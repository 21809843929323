/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AuthorizationKeyToRole } from './authorizationKeyToRole';


export interface Role { 
    id?: number;
    name?: string | null;
    description?: string | null;
    authorizationKeyToRoles?: Array<AuthorizationKeyToRole> | null;
}

