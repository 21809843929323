/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AuditEvent { 
    id?: number;
    apiKeyName?: string | null;
    action?: string | null;
    item?: string | null;
    itemId?: string | null;
    message?: string | null;
    date?: string;
}

