import { Component, Inject, Renderer2, TemplateRef } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@core/auth.service';
import { CommonModule, DOCUMENT, KeyValue } from '@angular/common';
import { ToastNotificationService } from '@core/toast-notification.service';
import { ApiKeyManagerService } from '@api/api';
import { Menu } from '@model/menu';
import { NgxRolesService, NgxPermissionsConfigurationService } from 'ngx-permissions';
import { BuConfigService } from '@core/bu-config.service';
import { UserService } from '@core/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
/** header component*/
export class HeaderComponent {

  public themeDic: KeyValue<string, string>[] = [];
  public currentStyle = "default";

  editForm: FormGroup;
  public showMenu = false;
  public superUser = false;
  menus: Menu;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private authService: AuthService,
    private toastService: ToastNotificationService,
    @Inject(DOCUMENT) private document: Document,
    private apiKeyService: ApiKeyManagerService,
    private rolesService: NgxRolesService,
    private readonly ngxpcs: NgxPermissionsConfigurationService,
    private renderer2: Renderer2,
    private buConfigService: BuConfigService,
    private userService: UserService) {
    this.authService.apiKey.subscribe(() => {
      this.setKeyStyle();
      this.showMenu = this.authService.hasApiKey() || this.userService.loggedIn;

      if (!this.showMenu) {
        this.router.navigate(['/app']);
      } else {
        if (!this.menus) {
          this.loadMenus();
        }
      }

    });

    this.themeDic.push({ key: "default", value: "Default" });
    this.themeDic.push({ key: "cosmo", value: "Cosmo" });
    this.themeDic.push({ key: "dark", value: "Dark" });
    this.themeDic.push({ key: "flat", value: "Flat" });
    this.themeDic.push({ key: "lumen", value: "Lumen" });
    this.themeDic.push({ key: "lux", value: "Lux" });
    this.themeDic.push({ key: "sandstone", value: "Sandstone" });
    this.themeDic.push({ key: "sketch", value: "Sketch" });
    this.themeDic.push({ key: "sketch-dark", value: "Sketch Dark" });
    this.themeDic.push({ key: "slate", value: "Slate" });
    this.themeDic.push({ key: "superhero", value: "Superhero" });
    this.themeDic.push({ key: "yeti", value: "Yeti" });
  }

  ngOnInit(): void {

    var selStyle = window.localStorage.getItem('selectedStyle');
    if (selStyle)
      this.loadStyle(selStyle);
    else
      this.loadStyle(this.currentStyle);

    this.currentStyle = selStyle;

    this.editForm = this.formBuilder.group({
      authkey: new FormControl('', Validators.required)
    });
  }

  open(content) {
    this.modalService.open(content, { windowClass: 'modal-holder', ariaLabelledBy: 'addAuthKey', centered: false });
  }

  isBULoginReq() {
    return this.buConfigService.getBuConfig()?.BUData?.isUALoginReq || false;
  }

  closeModal(modal) {
    modal.close();
    //setTimeout(function () {  }, 1500);
  }

  public setKeyStyle(): string {
    if (this.authService.hasApiKey()) {
      return 'text-success';
    } else {
      return 'text-danger';
    }
  }

  onSubmit() {
    this.apiKeyService.apiV2ApiKeyManagerAuthkeyPost({"value": this.editForm.controls.authkey.value}).subscribe({
      next: (data) => {
        if (!data.error) {
          this.authService.setApiKey(this.editForm.controls.authkey.value);
          this.editForm.controls.authkey.setValue('');
          this.modalService.dismissAll();
          //this.menus = data.data;
        }
        this.toastService.showResult(data.error, data.messages);
        return;
      }
    });
  }

  onClear() {
    this.authService.setApiKey(null);
    this.editForm.controls.authkey.setValue('');
    this.toastService.showSuccess("Key has been cleared");
    this.menus = null;
    this.rolesService.flushRolesAndPermissions();
    this.userService.logout();
    return;
  }

  loadMenus() {
    var key = this.authService.getApiKey();
    this.apiKeyService.apiV2ApiKeyManagerAuthkeyPost({ "value": key }).subscribe({
      next: (data) => {
        if (!data.error) {
          this.menus = data.data;
          this.rolesService.addRoleWithPermissions(data.data.role, data.data.permissions);
          this.ngxpcs.addPermissionStrategy('disable', (templateRef: TemplateRef<any>) => {
            this.renderer2.setAttribute(templateRef.elementRef.nativeElement.previousElementSibling, 'disabled', 'true');
          });
          this.ngxpcs.addPermissionStrategy('enable', (templateRef: TemplateRef<any>) => {
            this.renderer2.removeAttribute(templateRef.elementRef.nativeElement.previousElementSibling, 'disabled');
          });
          //this.ngxpcs.setDefaultOnAuthorizedStrategy('enable');
          //this.ngxpcs.setDefaultOnUnauthorizedStrategy('disable');
        }
      }
    });
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName + '.css';
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}` + '.css';

      head.appendChild(style);
    }

    window.localStorage.setItem('selectedStyle', styleName);
    this.currentStyle = styleName;
    //console.log(this.currentStyle);
    //console.log(styleName);
  }


}
