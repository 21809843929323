<div class="card border-0" *ngIf="course">
    <div class="card-header">
        <h5 class="card-title mb-0" ngbTooltip="Course name">
            {{course?.name}}
        </h5>
    </div>
    <div class="no-gutters m-2" *ngIf="!details">
        <!-- <div >
            <img [src]="getImageUrl(course?.type?.templateId)" class="card-img" />
        </div> -->
        <div >
            <h5 class="card-title mb-3" ngbTooltip="Certificate title">
                {{course?.title}}
            </h5>
            <ul class="list-group list-group-flush border-0">
                <li class="list-group-item">
                    <small>{{ course.secondaryTypes.length + 1 }} certificate{{
                        course.secondaryTypes.length > 0 ? "s" : ""
                    }} created for this course completion.</small>
                </li>
                <li class="d-flex d-flex justify-content-between align-items-center list-group-item">
                    <span>LMS Course Id</span>
                    <span>{{course.lmsItem?.courseId}}</span>
                </li>
                <li class="d-flex d-flex justify-content-between align-items-center list-group-item">
                    <span>LMS Source</span>
                    <span>{{getLmsName(course.lmsItem?.source)}}</span>
                </li>
                <li class="d-flex d-flex justify-content-between align-items-center list-group-item">
                    <span>Type</span>
                    <!-- <span style="width: 80%; margin-left: 50px;">{{course.type.type}}</span> -->
                    <span>{{course.type.type}}</span>
                </li>
                <li class="d-flex d-flex justify-content-between align-items-center list-group-item" *ngIf="course.brightlinkCredentialTypeSlug">
                  <span>Brightlink Credential Type</span>
                  <span>{{course.brightlinkCredentialTypeSlug}}</span>
              </li>
                <li class="d-flex d-flex justify-content-between align-items-center list-group-item">
                    Valid {{ course.type.duration}} {{ getDurationtype(course.type.durationType) }}
                </li>
            </ul>
        </div>
    </div>

    <div class="row no-gutters m-2" *ngIf="details">
        <div class="col-sm-8">
            <h4 class="card-title mb-3" ngbTooltip="Certificate title">
                {{course?.title}}
            </h4>
            <ul class="list-group list-group-flush border-0">
                <li class="list-group-item">
                    This course will render
                    {{ course.secondaryTypes.length + 1 }} certificate{{
                        course.secondaryTypes.length > 0 ? "s" : ""
                    }}
                    on creation.
                </li>
                <li class="d-flex d-flex justify-content-between align-items-center list-group-item">
                    <span>LMS Course Id</span>
                    <span>{{course.lmsItem.courseId}}</span>
                </li>
                <li class="d-flex justify-content-between align-items-center list-group-item">
                    <span>LMS Source</span>
                    <span>{{getLmsName(course.lmsItem.source)}}</span>
                </li>
                <li class="d-flex justify-content-between align-items-center list-group-item">
                    <span>Award State</span>
                    <StatusBadge [status]="course.certificateStatusOnAward"></StatusBadge>
                </li>
                <li class="d-flex justify-content-between align-items-center list-group-item">
                    <span>Earn</span>
                    <span>
                        <span class="fa-stack" *ngIf="course.ceu > 0 && course.earn" ngbTooltip="Earns a certificate with a CEU value of {{course.ceu}}">
                          <i aria-hidden="true" class="fa fa-certificate fa-stack-2x text-success"></i>
                          <strong class="fa-stack-1x text-white"><small>{{ course.ceu | number }}</small></strong>
                        </span>
                        <i aria-hidden="true" class="fa fa-check text-success fa-lg" *ngIf="course.ceu == 0 && course.earn" ngbTooltip="Earns a certificate without CEU"></i>
                        <label *ngIf="!course.earn"><i aria-hidden="true" class="fa fa-ban text-danger fa-lg" ngbTooltip="Does not earn a certificate" data-toggle="tooltip"></i></label>
                    </span>
                </li>
                <li *ngIf="issuedDate" class="d-flex d-flex justify-content-between align-items-center list-group-item">
                    <span>Issue Date</span>
                    <span>{{issuedDate}}</span>
                </li>
                <li *ngIf="issuedDate" class="d-flex d-flex justify-content-between align-items-center list-group-item">
                    <span>Expiration Date</span>
                    <span>{{getExpireDate()}}</span>
                </li>
            </ul>
        </div>
        <div class="col-sm-4">
            <img [src]="getImageUrl(course?.type?.templateId)" class="card-img" />
        </div>
    </div>
</div>
