/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocGenOptions } from './docGenOptions';
import { BadgingOptions } from './badgingOptions';
import { KafkaOptions } from './kafkaOptions';
import { DataCopy } from './dataCopy';
import { RulesEngineOptions } from './rulesEngineOptions';
import { BrightlinkIntegrationOptions } from './brightlinkIntegrationOptions';
import { UaOptions } from './uaOptions';


export interface CertificateOptions { 
    useVault?: boolean;
    applicationName?: string | null;
    authorizationKeys?: { [key: string]: string; } | null;
    docGen?: DocGenOptions;
    ua?: UaOptions;
    kafka?: KafkaOptions;
    renewSource?: string | null;
    rulesEngine?: RulesEngineOptions;
    enableAuditEventsToDatabase?: boolean;
    dataCopy?: DataCopy;
    menuFile?: string | null;
    badgingOptions?: BadgingOptions;
    /**
     * original or latest
     */
    renewalCondition?: string | null;
    brightlinkIntegration?: BrightlinkIntegrationOptions;
}

