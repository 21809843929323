import { Component, Input, Output, OnInit } from '@angular/core';
import { CertificateStatusCodeEnum } from '@model/models';
import { BehaviorSubject } from 'rxjs';

@Component({
  //selector: 'app-certificate-status-dropdown',
  selector: 'StatusDropdown',
  templateUrl: './certificate-status-dropdown.component.html',
  styleUrls: ['./certificate-status-dropdown.component.scss']
})
export class CertificateStatusDropdownComponent implements OnInit {
  constructor() { } 

  public statuses: CertificateStatusCodeEnum;

  @Input() public name: string;
  @Input() public value = null;

  private currentStatus = new BehaviorSubject<CertificateStatusCodeEnum>(this.value);
  @Output() public change = this.currentStatus.asObservable();
 
  ngOnInit() {
    //console.log('app-certificate-status-dropdown init', this.name, this.value);
  }

  getStatusList() {
    return Object.keys(CertificateStatusCodeEnum).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }

  setStatus(status: CertificateStatusCodeEnum) {
    console.log("new status", status);
    this.value = status;
    this.currentStatus.next(status);
  }
}
