import { Component, OnInit, OnDestroy, Output, Input, PipeTransform } from '@angular/core';
import { CertificateTemplate } from '@model/models';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TemplatesService } from '@api/api';
import { AuthService } from '@core/auth.service';
import { ToastNotificationService } from '@core/toast-notification.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { LowerCasePipe } from '@angular/common';

@Component({
  selector: 'app-meta-template-selector',
  templateUrl: './meta-template-selector.component.html',
  styleUrls: ['./meta-template-selector.component.scss']
})
/** MetaTemplateSelector component*/
export class MetaTemplateSelectorComponent implements OnInit, OnDestroy {

  @Input() public templateId: number;

  subs: Subscription[] = [];
  public templates: CertificateTemplate[];
  private pt: CertificateTemplate[];
  private currentType = new BehaviorSubject(null);

  @Output() selected = this.currentType.asObservable();

  filterForm: FormGroup;

  /** MetaTemplateSelector ctor */
  constructor(private apiTemplates: TemplatesService,
    private auth: AuthService,
    private toastService: ToastNotificationService,
    private pipe: LowerCasePipe,
    private formBuilder: FormBuilder) {

    this.filterForm = this.formBuilder.group({
      filter: ['']
    });
  }

  ngOnInit(): void {
    console.log('meta-template-selector init');

    this.apiTemplates.configuration.apiKeys = { "Authorization": this.auth.getApiKey() };

    this.apiTemplates.apiV2TemplatesGet()
      .subscribe({
        next: res => {
          if (!res.error) {
            this.templates = res.data?.filter( x => x.isActive == true);
            this.pt = res.data?.filter( x => x.isActive == true);
          }
          else
            this.toastService.showResult(res.error, res.messages);
        },
        error: err => this.toastService.showResult(true, err.error.messages)
      });

    this.filterForm.controls['filter'].valueChanges
      .pipe(startWith(''), map(text => this.search(text, this.pipe)))
      .subscribe(results => {
        this.templates = results;
      });
  }

  ngOnDestroy() {
    for (let s of this.subs) {
      s.unsubscribe();
    }
  }

  setSelectedTemplate(templateId: number) {
    this.templateId = templateId;
    console.log(templateId + ' selected');
    this.currentType.next(templateId);
  }

  search(text: string, pipe: PipeTransform): CertificateTemplate[] {
    console.log(text);
    console.log(this.templates?.length);
    return this.pt?.filter(cert => {
      const term = text.toLowerCase();
      return pipe.transform(cert.name).includes(term)
        || pipe.transform(cert.tags.join(", ")).includes(term);
    });
  }

  get templateColl(): CertificateTemplate[] {
    return this.templates;
  }

  getImageUrl(id: number): string {
    return "/api/v2/templates/" + id + ".jpg";
  }

}
