import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import jwt_decode from "jwt-decode";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { UaUser } from '../model_custom/uaUser';
import { BuConfigService } from './bu-config.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  loggedIn: boolean = false;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private buConfigService: BuConfigService
  ) {
    var token = this.getUaTokenFromCache();
    if (token) {
      this.loggedIn = true;
    }
  }

  decodeJwtToken(): string {
    var cookieValue: string;
    cookieValue = this.getUaTokenFromCache();

    if (cookieValue) {
      let decoded = jwt_decode<string>(cookieValue);
      console.log(decoded["user_id"]);
      return decoded;
    } else {
      return "";
    }
  }

  getUserData(): Observable<UaUser> {
    let jwtToken: string = this.decodeJwtToken();
    let userId = jwtToken['user_id'];
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'X-XSRF-TOKEN': this.getUaTokenFromCache()
      })
    }
    return this.http.get<UaUser>(this.buConfigService.getBuConfig().BUData.apiURL + '/api/v3/users/' + userId, httpOptions);
  }

  getUaTokenFromCache() {
    var xsrfToken = this.cookieService.get('XSRF-TOKEN');
    return xsrfToken;
  }

  logout(returnUrl?: string) {
    let qPram: string = '';
    if (returnUrl) {
      qPram = encodeURI(`?return=${returnUrl}`);
    }
    var url = `${this.buConfigService.getBuConfig().BUData.apiURL}/logout?service=${this.buConfigService.getBuConfig().BUData.serviceURL}${qPram}&gateway=true`;
    this.cookieService.delete('XSRF-TOKEN');

    window.location.href = url;
  }

}
