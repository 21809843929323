import { Inject, Injectable } from '@angular/core';
import { UAConfig } from '../model_custom/uaConfig';
import { DOCUMENT } from '@angular/common';
import * as uaJson from '../../assets/buConfig.json';

@Injectable({
  providedIn: 'root'
})
export class BuConfigService {

  private uaData: UAConfig;

  constructor(@Inject(DOCUMENT) private document: any) {
    this.buConfig();
  }

  buConfig() {
    console.log(this.document.location.hostname);
    let jsonData = (uaJson as any)?.default;
    this.uaData = jsonData?.filter(x => x.BUURL.find(y => y == this.document.location.hostname))[0];
  }

  getBuConfig(): UAConfig {
    return this.uaData;
  }
}
