import { Component } from '@angular/core';
import { Router, RouterEvent, NavigationStart, NavigationEnd } from '@angular/router';
import { BuConfigService } from '@core/bu-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';

  showLoadingSpinner = true;
  constructor(
      private router: Router,
      private buConfigService: BuConfigService
    ) {
    this.router.events.subscribe((re) => {
      if (re instanceof NavigationStart) {
        this.showLoadingSpinner = true;
      }

      if (re instanceof NavigationEnd) {
        this.showLoadingSpinner = false;
      }
    });
    if(this.buConfigService.getBuConfig()?.BUData) {
      this.loadJS();
    }
  }

  loadJS() {
    // DOM: Create the script element
    var jsElm = document.createElement("script");
    // set the type attribute
    jsElm.type = "application/javascript";
    // make the script element load file
    jsElm.src = this.buConfigService.getBuConfig()?.BUData?.js;
    // finally insert the element to the body element in order to load the script
    document.body.appendChild(jsElm);
  }
}
