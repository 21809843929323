import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastNotificationService {

  toasts: any[] = [];

  constructor() { }

  showSuccess(textOrTpl: string | TemplateRef<any>) {
    this.toasts.push({ textOrTpl, header: "Success", classname: 'toast-success', delay: 10000 });
  }

  showError(textOrTpl: string | TemplateRef<any>) {
    this.toasts.push({ textOrTpl, header: "Error", classname: 'toast-error', delay: 10000 });
    //this.toasts.push({ textOrTpl, header: "Error" });
  }

  showWarning(textOrTpl: string | TemplateRef<any>) {
    this.toasts.push({ textOrTpl, header: "Warning", classname: 'toast-warning', delay: 10000 });
  }

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    //console.log('toast svc ' + textOrTpl);
    this.toasts.push({ textOrTpl, ...options });
  }

  showResult(error: boolean, text: string[]) {
    if (error)
      this.showError(text.join("<br />"));
    else
      this.showSuccess(text.join("<br />"));
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
