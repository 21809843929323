/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SearchResult { 
    firstName?: string | null;
    lastName?: string | null;
    certNumber?: string | null;
    courseTitle?: string | null;
    issuedDate?: string;
    expirationDate?: string | null;
    state?: string | null;
    status?: string | null;
}

