import { Component, Output, Input, PipeTransform } from '@angular/core';
import { CertificateTemplate } from '@model/models';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TemplatesService } from '@api/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@core/auth.service';
import { ToastNotificationService } from '@core/toast-notification.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { LowerCasePipe } from '@angular/common';

@Component({
  selector: 'app-template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss']
})
/** template-card component*/
export class TemplateCardComponent {

  @Input() public templateId: number;

  subs: Subscription[] = [];
  public templates: CertificateTemplate[];
  //private currentType = new BehaviorSubject(this.templateId);
  public template: CertificateTemplate;

  /** template-card ctor */
  constructor(private apiTemplates: TemplatesService,
    private auth: AuthService,
    private toastService: ToastNotificationService,
    private router: Router,
    private pipe: LowerCasePipe,
    private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    console.log('meta-template-selector init');
    this.apiTemplates.configuration.apiKeys = { "Authorization": this.auth.getApiKey() };

    this.apiTemplates.apiV2TemplatesGet()
      .subscribe({
        next: res => {
          if (!res.error) {
            this.templates = res.data;
            this.setTemplate();
          }
          else
            this.toastService.showResult(res.error, res.messages);
        },
        error: err => this.toastService.showResult(true, err.error.messages)
      });
  }

  setTemplate() {
    this.template = this.templates.filter(t => t.id == this.templateId).slice(0, 1)[0];
  }

  getImageUrl(id: number): string {
    return "/api/v2/templates/" + id + ".jpg";
  }
}
