import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpCacheService } from './http-cache.service';
import { CacheMapService } from '@core/cache-map.service'

//const CACHABLE_URL = "/app/meta";

//const CACHEABLES = ["templates", "types", "coursemetas"];
const CACHEABLES = ["templates", "types"];

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

    constructor(private cache: CacheMapService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        var isCacheable = this.isRequestCachable(req);

        if (req.method !== 'GET' && isCacheable) {
            this.cache.invalidateCache();
            return next.handle(req);
        }

        if (!isCacheable) {
           return next.handle(req);
        }
        const cachedResponse = this.cache.get(req);
        if (cachedResponse !== null) {
           return of(cachedResponse);
        }
        return next.handle(req).pipe(
           tap(event => {
              if (event instanceof HttpResponse) {
                this.cache.put(req, event);
              }
           })
        );
    }
    private isRequestCachable(req: HttpRequest<any>) {
        //return (req.method === 'GET') && (req.url.indexOf(CACHABLE_URL) > -1);

        var isCacheable = false;
        CACHEABLES.forEach(element => {
            if (req.url.indexOf(element) > -1) {
                isCacheable = true;
            }
        });



        return isCacheable;
    }


//   constructor(private cacheService: HttpCacheService) { }

//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

//     // pass along non-cacheable requests and invalidate cache
//     if(req.method !== 'GET') {
//       console.log(`Invalidating cache: ${req.method} ${req.url}`);
//       this.cacheService.invalidateCache();
//       return next.handle(req);
//     }

//     // attempt to retrieve a cached response
//     const cachedResponse: HttpResponse<any> = this.cacheService.get(req.url);

//     // return cached response
//     if (cachedResponse) {
//       console.log(`Returning a cached response: ${cachedResponse.url}`);
//       console.log(cachedResponse);
//       return of(cachedResponse);
//     }

//     // send request to server and add response to cache
//     return next.handle(req)
//       .pipe(
//         tap(event => {
//           if (event instanceof HttpResponse) {
//             console.log(`Adding item to cache: ${req.url}`);
//             this.cacheService.put(req.url, event);
//           }
//         })
//       );

//   }
}
