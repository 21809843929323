import { Component, TemplateRef } from '@angular/core';
import { ToastNotificationService } from '@core/toast-notification.service';

@Component({
  selector: 'app-toast-notification',
  host: { '[class.ngb-toasts-left]': 'true' },
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss']
})
/** toast-notification component*/
export class ToastNotificationComponent {
  constructor(public toastService: ToastNotificationService) { }

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }
}
