
  <div class="card bg-info border-info">
    <h4 class="card-title p-2 mb-0">Certificate Type</h4>
    <div class="list-group list-group-flush" style="max-height: 500px; overflow-y: scroll; overflow-x: no-display" id="typeScroll">

      <div class="list-group-item">
        <div [formGroup]="filterForm">
          <div class="form-group">
            <input class="form-control" type="text" formControlName="filter" id="filter" placeholder="filter on type name" autocomplete="off" />
          </div>
        </div>
      </div>

      <div class="list-group-item" *ngFor="let item of filteredTypes" (click)="setSelectedType(item)"
        [ngClass]="{'active': type?.id == item.id, 'text-muted bg-light': type && type.id != item.id }"
        style="cursor: pointer;">
        <h5 class="mb-1">{{ item.type }}</h5>
        <div class="d-flex w-100 justify-content-between">
          <small>{{ item.renderTemplateId }}</small>
          <ng-container *ngIf="item.duration; else completion">
            <span class="badge badge-info border border-light">{{ item.duration }} {{ getDurationtype(item.durationType) }} </span>
          </ng-container>
          <ng-template #completion>
            <span class="badge badge-dark border border-light">Completion</span>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

