/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ICertificateMetadata } from './iCertificateMetadata';
import { LmsItem } from './lmsItem';
import { CertificateStatusCodeEnum } from './certificateStatusCodeEnum';
import { CourseMetaSecondaryType } from './courseMetaSecondaryType';
import { ExternalCredential } from './externalCredential';


export interface CourseMeta { 
    readonly contactHours?: number;
    type?: ICertificateMetadata;
    id?: number;
    name: string;
    earn?: boolean;
    title?: string | null;
    subTitle?: string | null;
    notes?: string | null;
    typeId?: number;
    ceu?: number;
    created?: string;
    modified?: string;
    duration?: number;
    certificateStatusOnAward?: CertificateStatusCodeEnum;
    graceExpireDays?: number;
    externalCredential?: ExternalCredential;
    secondaryTypes?: Array<CourseMetaSecondaryType> | null;
    lmsItems?: Array<LmsItem> | null;
    isActive?: boolean;
    brightlinkCredentialTypeSlug?: string | null;
}

