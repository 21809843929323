/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CertificateStatusCodeEnum } from './certificateStatusCodeEnum';


/**
 * create a backdated certificate for a user with a pre-defined certificate number
 */
export interface BackdatedCertificate { 
    /**
     * user id to add certificate
     */
    uaId: number;
    /**
     * pre-definend certificate number - if the cert number already exists it will fail
     */
    certNumber: string;
    /**
     * certificate title
     */
    title: string;
    /**
     * type of certifcate
     */
    type: string;
    /**
     * source of request - possible values Moodle, Admin, CRM, Test
     */
    source: string;
    /**
     * date of issue
     */
    issuedDate: string;
    /**
     * certificate expiration
     */
    expirationDate?: string | null;
    certificateStatus?: CertificateStatusCodeEnum;
    /**
     * type id for cert
     */
    certMetadataId?: number;
    /**
     * notes for certificate notes
     */
    notes?: string | null;
    /**
     * CEU\'s associated to cert
     */
    ceu?: string | null;
    /**
     * source cross reference
     */
    sourceXRef?: string | null;
    courseMetaId?: number | null;
    lmsCourseId?: string | null;
    lmsSource?: string | null;
    lmsItemId?: number | null;
    graceExpireDays?: number;
}

