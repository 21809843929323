import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MetaTypeSelectorComponent } from './meta-type-selector/meta-type-selector.component';
import { MetaTemplateSelectorComponent } from './meta-template-selector/meta-template-selector.component';
import { CommonModule, LowerCasePipe } from '@angular/common';
import { TemplateCardComponent } from './template-card/template-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CertificateStatusDropdownComponent } from './certificate-status-dropdown/certificate-status-dropdown.component';
import { CertificateStatusBadgeComponent } from './certificate-status-badge/certificate-status-badge.component';
import { MetaCourseSelectorComponent } from './meta-course-selector/meta-course-selector.component';
import { CourseCardComponent } from './course-card/course-card.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  declarations: [
    MetaTypeSelectorComponent,
    MetaTemplateSelectorComponent,
    TemplateCardComponent,
    CertificateStatusDropdownComponent,
    CertificateStatusBadgeComponent,
    MetaCourseSelectorComponent,
    CourseCardComponent
  ],
  exports: [
    MetaTypeSelectorComponent,
    MetaTemplateSelectorComponent,
    MetaCourseSelectorComponent,
    
    TemplateCardComponent,
    CertificateStatusDropdownComponent,
    CertificateStatusBadgeComponent,
    CourseCardComponent,
    NgbModule
  ],
  providers: [
    LowerCasePipe
  ]
})
export class SharedModule {
}

export function GetLmsName_SH(val: number): string {
  if (val == 0)
    return "Moodle";
  else if (val == 1)
    return "Crowd Wisdom";
  else 
    return "Other"
}
