/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AwardDetails { 
    lmsCourseId?: string | null;
    lmsSource?: string | null;
    activityId?: string | null;
    uaId?: number;
    issuedDate?: string;
    examLocation?: string | null;
    enrollmentId?: string | null;
    enrollmentDate?: string | null;
    score?: number | null;
}

