/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Topic } from './topic';


export interface KafkaOptions { 
    /**
     * name used consuming topics  name used for publishing messages    Should be unique across instances such as namespace
     */
    applicationName?: string | null;
    useKafka?: boolean;
    schemaRegistry?: string | null;
    kafkaEndpoint?: string | null;
    topics?: { [key: string]: Topic; } | null;
}

