
<div class="card w-100">
  <div class="card-img-top d-flex align-items-center bg-light">
    <div>
      <img class="card-img" [src]="getImageUrl(template?.id)" [alt]="template?.name" style="width:150px">
    </div>
    <div class="col p-2 m-0">
      <h5 class="card-title">{{ template?.name }}</h5>
      <p class="card-text">{{ template?.tags }}</p>
    </div>
  </div>
</div>
