/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ICertificateMetadata } from './iCertificateMetadata';


/**
 * returns data and/or messages associated
 */
export interface ICertificateMetadataIEnumerableIResult { 
    /**
     * data returned from request
     */
    data?: Array<ICertificateMetadata> | null;
    error?: boolean;
    messages?: Array<string> | null;
}

