import { Injectable } from '@angular/core';
import { HttpHandler, HttpEvent, HttpRequest, HttpResponse, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoadNotificationService } from './load-notification.service';
import { finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class LoadNotificationInterceptor implements HttpInterceptor {

  constructor(public loaderService: LoadNotificationService, private router: Router) { }

  private totalRequests = 0;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log('Loader interceptor');
    this.totalRequests++;
    this.loaderService.show();
    return next.handle(req).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0  )
          this.loaderService.hide();
      }),
      tap(() => {  },
        (err: any) => {
          if (err.status == 401 || err.status == 403) {
            this.router.navigate(['app', 'admin', 'unauthorized']);
          } else {
            return;
          }
        }
      ));




      //map((event: HttpEvent<any>) => {
      //  if (event instanceof HttpResponse) {
      //    console.log('event--->>>', event);
      //  }
      //  return event;
      //}),
      //catchError((error: HttpErrorResponse) => {
      //  //let data = {};
      //  //data = {
      //  //  reason: error && error.error && error.error.reason ? error.error.reason : '',
      //  //  status: error.status
      //  //};
      //  //console.log(error);

      //  if (error?.status == 403 || error?.status == 401) {
      //    this.router.navigate(['app', 'admin', 'unauthorized']);
      //  } else {
      //    this.router.navigate(['app']);
      //  }
      //  //this.errorDialogService.openDialog(data);
      //  return throwError(error);
      //}));


    //);
  }
}
