import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BuConfigService } from '@core/bu-config.service';
import { UserService } from '@core/user.service';
import { CookieService } from 'ngx-cookie-service';
import $ from 'jquery';
import { ToastNotificationService } from '@core/toast-notification.service';
import { UaUser } from '../model_custom/uaUser';
import { UALoginService } from '@api/uALogin.service';
import { AuthService } from '@core/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  @ViewChild('div') div: ElementRef;
  customError: string = '';
  public nonAdAccount: boolean = false;
  subs: Subscription[] = [];

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer2: Renderer2,
    private userService: UserService,
    private buConfigService: BuConfigService,
    private toastService: ToastNotificationService,
    private uaLoginService: UALoginService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    if ($('.ua-error-msg').html() === "Please enter a valid Username and Password."
      && this.customError !== '') {
      this.customError = '';
    }

    $('.ua-submit').addClass('ua-submit2');
    $('.ua-submit2').removeClass('ua-submit');

    var loginBtn = document.getElementById('btnLogin');
    if (loginBtn == null) {

      const button = this.renderer2.createElement('button') as HTMLButtonElement;
      button.setAttribute("class", "btn btn-lg btn-primary text-uppercase ua-submit1");
      button.setAttribute("id", "btnLogin");
      button.setAttribute("tabindex", "3");

      const buttonText = this.renderer2.createText('LOGIN');
      this.renderer2.appendChild(button, buttonText);

      var existingNode = document.querySelector(".ua-submit2");
      if (existingNode) {
        existingNode.parentNode.insertBefore(button, existingNode.nextSibling);
        this.renderer2.listen(button, 'click', () => this.doLogin());
      }
    }

    $('.ua-submit-username').on('click', function () {
      var txt = $('#ua-reset-username');
      if (txt.val() != null && txt.val() != '') {
        $(".ua-cancel-link").addClass('ua-cancel-link1');
      }
    });

    $('.ua-submit-email').on('click', function () {
      $(".ua-verify-otp").addClass('verfiy-mobile-after-email-click');
      $(".ua-cancel-link1").addClass("cancel-after-email-click");

      if ($('.ua-submit-email')) {
        $("#email-alert").addClass("email-alert-msg");
        $("#email-alert").removeClass("email-alert-msg-hide");
      }

      $('.ua-cancel-link').on('click', function () {
        $("#email-alert").addClass("email-alert-msg-hide");
      });
    });

    $('.ua-submit2').removeClass('btn-block');

    var tmpUsername = document.getElementById("ua-username");
    if (tmpUsername)
      tmpUsername.setAttribute("tabindex", "1");

    var tmpPassword = document.getElementById("ua-password");
    if (tmpPassword)
      tmpPassword.setAttribute("tabindex", "2");

  }

  doLogin() {
    var loginBtn = document.querySelector('.ua-submit2') as HTMLButtonElement;
    if (loginBtn && this.buConfigService.getBuConfig().BUData.isUALoginReq) {
      loginBtn.click();
      loginBtn.disabled = true;
      var loginBtn1 = document.querySelector('.ua-submit1') as HTMLButtonElement;
      loginBtn1.disabled = true;
      setTimeout(function () { loginBtn.disabled = false; loginBtn1.disabled = false; }, 5000);
    } else if (!this.buConfigService.getBuConfig().BUData.isUALoginReq) {
      this.toastService.showError("UA Login is not avalaible.");
    }
  }

  ngAfterViewInit() {
    if (this.userService.getUaTokenFromCache()) {
      this.getUserData();
    } else {
      this.loadLoginWidget();
    }
  }

  loadLoginWidget() {
    const uaLog: HTMLElement = this.renderer2.createElement('ua-ng-login');
    uaLog.setAttribute('id', 'uaLogin');
    uaLog.setAttribute('api-version', 'v3');
    uaLog.setAttribute('tos-enabled', 'false');
    uaLog.setAttribute('reset-enabled', 'false');
    uaLog.setAttribute('register-enabled', 'false');

    uaLog.setAttribute('widget-root', 'login');
    uaLog.setAttribute('authentication-key', 'username');
    uaLog.setAttribute('address-enabled', 'false');

    if (this.buConfigService.getBuConfig().BUData) {
      console.log(this.buConfigService.getBuConfig());
      uaLog.setAttribute('cas-url', this.buConfigService.getBuConfig()?.BUData?.casURL);
      uaLog.setAttribute('api-url', this.buConfigService.getBuConfig()?.BUData?.apiURL);
      uaLog.setAttribute('bu-name', this.buConfigService.getBuConfig()?.BUData?.buName);
      uaLog.setAttribute('bu-domain', this.buConfigService.getBuConfig()?.BUData?.buDomain);
      uaLog.setAttribute('service-url', this.buConfigService.getBuConfig()?.BUData?.serviceURL);
    }
    else {
      uaLog.setAttribute('cas-url', 'https://dev-auth.examfx.com');
      uaLog.setAttribute('api-url', 'https://dev-auth.examfx.com');
      uaLog.setAttribute('bu-name', 'ExamFX');
      uaLog.setAttribute('bu-domain', '.examfx.com');
      uaLog.setAttribute('service-url', 'https://examfx.com');
    }

    this.renderer2.appendChild(this.div.nativeElement, uaLog);

    const uaLogin = document.querySelector('ua-ng-login');

    uaLogin.addEventListener('loginEvent', (data) => {
      this.customError = '';
      this.getUserData();
    });
  }

  getUserData() {
    this.subs.push(this.userService.getUserData().subscribe({
      next: userData => {
        if (userData) {
          this.setToken(userData);
        }
      },
      error: (error) => {
        this.toastService.showResult(true, error.messages);
      }
    }));
  }

  setToken(uaUser: UaUser) {
    this.subs.push(this.uaLoginService.apiV2UALoginPost(uaUser.data.permissions).subscribe({
      next: data => {
        if (!data.error) {
          console.log(data.data);
          this.authService.setApiKey(data.data?.key);
          window.sessionStorage.setItem('UAUsername', uaUser?.data?.username);
          this.router.navigate(['/app']);
        } else {
          this.toastService.showError("Error logging in");
        }
      },
      error: (error) => {
        this.toastService.showResult(true, error.error.messages);
        this.userService.logout();
        this.router.navigate(['/app']);
      }
    }));
  }

  ngOnDestroy() {
    for (let s of this.subs) {
      s.unsubscribe();
    }
  }

}
