import { Component, Input, OnInit } from '@angular/core';
import { CourseMeta } from '@model/courseMeta';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { CourseMetaFlatten } from 'src/app/model_custom/courseMetaFlatten';

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss']
})
export class CourseCardComponent implements OnInit {

  @Input() public course: CourseMetaFlatten;
  @Input() public issuedDate = moment.utc().format("YYYY-MM-DD");
  @Input() public details = true;

  constructor() { }

  ngOnInit(): void {
  }

  getImageUrl(id: number): string {
    return "/api/v2/templates/" + id + ".jpg";
  }

  getLmsName(val): string {
    if (val == 0)
      return "Moodle";
    else if (val == 1)
      return "Crowd Wisdom";
    else 
      return "Other"
  }

  getExpireDate() {
    var expAdd = this.course.type.duration;
    var issued = moment(this.issuedDate);
    
    if (this.course.type.durationType == "d") {
      return issued.add(expAdd, 'days').format("YYYY-MM-DD");
    } else if (this.course.type.durationType == "y") {
      return issued.add(expAdd, 'years').format("YYYY-MM-DD");
    } else {
      return issued.add(expAdd, 'M').format("YYYY-MM-DD");
    }
    //return issued.add(expAdd, 'M').format("YYYY-MM-DD");
  }

  getDurationtype(type): string {
    if(type == "d") {
      return "Days";
    } else if(type == "y") {
      return "Years"
    } else {
      return "Months";
    }
  }

}
