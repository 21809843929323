import { Component } from '@angular/core';
import { LoadNotificationService } from '@core/load-notification.service';
//import { LoadNotificationService } from '@app/load-notification.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-load-notification',
    templateUrl: './load-notification.component.html',
    styleUrls: ['./load-notification.component.scss']
})
/** LoadNotification component*/
export class LoadNotificationComponent {
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  constructor(private loaderService: LoadNotificationService) { }
}
