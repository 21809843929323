import { NgModule, ModuleWithProviders, SkipSelf, Optional, Injectable } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

//import { CertificateTypesService } from './api/certificateTypes.service';
//import { CertificatesV2Service } from './api/certificatesV2.service';
//import { CourseMetasService } from './api/courseMetas.service';
//import { RulesEngineService } from './api/rulesEngine.service';
//import { StatsService } from './api/stats.service';
//import { TemplatesService } from './api/templates.service';
//import { UserMigrationService } from './api/userMigration.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: []
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
        'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
