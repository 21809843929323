import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { StatsService } from '../api/api';
import { JobOptions } from '@model/jobOptions';
import { AuthService } from '@core/auth.service';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { AuditEvent, AppDetails } from '../model/models';
import { BadgingAppDetails } from '../model/badgingAppDetails';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
/** stats component*/
export class StatsComponent implements OnInit, OnDestroy {

  public stats: AppDetails;
  public jobs: JobOptions;
  public auditEvents: AuditEvent[];
  public badgingAuditEvents: AuditEvent[];
  public badgingStats: BadgingAppDetails;

  appDetails$: BehaviorSubject<AppDetails>;

  //subs: Subscription[] = [];
  public activeTab = 1;

  constructor(private router: Router, private statsApi: StatsService, private auth: AuthService) {

  }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy() {
    // for (let s of this.subs) {
    //   s.unsubscribe();
    // }
  }

  loadData() {
    if (this.auth.hasApiKey()) {
      this.statsApi.configuration.apiKeys = { "Authorization": this.auth.getApiKey() };

      this.statsApi.apiV2StatsGet().subscribe({
        next: (data) => {
          this.stats = data
          if (this.stats?.options?.badgingOptions?.enabled) {
            this.statsApi.apiV2StatsAuditExtBadgingGet().subscribe({
              next: (data) => this.badgingAuditEvents = data
            });
          }
        }
      });

      this.statsApi.apiV2StatsJobsGet().subscribe({
        next: (data) => this.jobs = data
      });

      this.statsApi.apiV2StatsAuditGet().subscribe({
        next: (data) => this.auditEvents = data
      });

      this.statsApi.apiV2StatsStatsExtBadgingGet().subscribe({
        next: (data) => this.badgingStats = data
      })
    }
    else
      this.router.navigate(["/app"]);
  }
}
