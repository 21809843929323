/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ILookupCertificateResult } from './iLookupCertificateResult';


export interface ILookupUserResult { 
    certificates?: Array<ILookupCertificateResult> | null;
    sfId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    id?: number;
    state?: string | null;
    email?: string | null;
    phone?: string | null;
    source?: string | null;
    readonly fullName?: string | null;
}

