import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { StatsComponent } from './stats/stats.component';

const routes: Routes = [

  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'app', component: HomeComponent, pathMatch: 'full' },
  { path: 'app/login', component: LoginComponent},
  { path: 'app/stats', component: StatsComponent },
  //{
  //  path: 'app/certificate-types',
  //  loadChildren: () => import('./types/types.module').then(m => m.TypesModule)
  //},
  {
    path: 'app/meta',
    loadChildren: () => import('./meta/meta-items.module').then(m => m.MetaItemsModule)
  },
  //{
  //  path: 'app/users',
  //  loadChildren: () => import('./awards/awards.module').then(m => m.AwardsModule)
  //},
  {
    path: 'app/awards',
    loadChildren: () => import('./awards/awards.module').then(m => m.AwardsModule)
  },

  {
    path: 'app/admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  //{ path: '*', component: HomeComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
