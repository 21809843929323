<ngb-toast *ngFor="let toast of toastService.toasts"
           [class]="toast.classname"
           [autohide]="true"
           [delay]="toast.delay || 10000"
           (hide)="toastService.remove(toast)"
           [header]="toast.header">

  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>

  
  <ng-template #text>
    <div [innerHtml]="toast.textOrTpl"></div>
  </ng-template>
</ngb-toast>
