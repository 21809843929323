import { Component, Output, Input, PipeTransform } from "@angular/core";
import { ICertificateMetadata } from '@model/models';
import { BehaviorSubject, Subscription, map, startWith } from 'rxjs';
import { CertificateTypesService } from '@api/api';
import { AuthService } from '@core/auth.service';
import { ToastNotificationService } from '@core/toast-notification.service';
import { FormBuilder, FormGroup } from "@angular/forms";
import { LowerCasePipe } from "@angular/common";

@Component({
  selector: 'app-meta-type-selector',
  templateUrl: './meta-type-selector.component.html',
  styleUrls: ['./meta-type-selector.component.scss']
})
/** MetaTypeSelector component*/
export class MetaTypeSelectorComponent {

  @Input() public type: ICertificateMetadata;
  @Input() public typeId: number;

  public metaTypes: ICertificateMetadata[];
  public filteredTypes: ICertificateMetadata[];

  private metaTypeList = new BehaviorSubject<ICertificateMetadata[]>(null);
  @Output() typesLoaded = this.metaTypeList.asObservable();

  private currentType = new BehaviorSubject<ICertificateMetadata>(null);
  @Output() selected = this.currentType.asObservable();

  filterForm: FormGroup;

  /** MetaTypeSelector ctor */
  constructor(private csApiTypes: CertificateTypesService,
    private auth: AuthService,
    private toastService: ToastNotificationService,
    private pipe: LowerCasePipe,
    private formBuilder: FormBuilder) {
    this.filterForm = this.formBuilder.group({
      filter: ['']
    });
  }

  ngOnInit(): void {
    console.log('meta-type-selector init');
    this.csApiTypes.configuration.apiKeys = { "Authorization": this.auth.getApiKey() };
  }

  ngAfterViewInit() {
    console.log('meta-type-selector after view init');
    setTimeout(() => {
      this.csApiTypes.apiV2TypesGet()
        .subscribe(
          {
            next: res => {
              if (!res.error) {
                this.metaTypes = res.data?.filter(x => x.isActive == true);
                this.metaTypeList.next(this.metaTypes);
                if (this.type == null && this.typeId > 0)
                  this.type = this.metaTypes.find(a => a.id == this.typeId);

                  this.filterForm.controls['filter'].valueChanges
                  .pipe(startWith(''), map(text => this.search(text, this.pipe)))
                  .subscribe(results => {
                    this.filteredTypes = results;
                    // this.collectionSize = results?.length;
                    // this.page = 1;
                  });
              }
              else
                this.toastService.showResult(res.error, res.messages);
            },
            error: err => this.toastService.showResult(true, err.error.messages)
          });
    });
  }

  ngOnDestroy() {
  }

  search(text: string, pipe: PipeTransform): ICertificateMetadata[] {
    // console.log(text);
    // console.log(this.courses?.length);
    return this.metaTypes?.filter(t => {
      const term = text.toLowerCase();
      //return pipe.transform(cm.name).includes(term)
      return t.type.toLowerCase().includes(term);
    });
  }

  setSelectedType(type: ICertificateMetadata) {
    this.type = type;
    this.typeId = type.id;
    console.log("type selected", this.type);
    this.currentType.next(type);
  }

  getDurationtype(type): string {
    if (type == "d") {
      return "Days";
    } else if (type == "y") {
      return "Years"
    } else {
      return "Months";
    }
  }
}
