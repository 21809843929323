/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Permissions { 
    user_permission_id?: number;
    permission_id?: number;
    permission_type?: string | null;
    name?: string | null;
    context?: string | null;
    expiration_at?: string;
    content_expiration?: string;
}

