
import { ICertificateMetadata } from './iCertificateMetadata';

export interface SecondaryType {
    //name: string;
    id?: number;
    typeId?: number;
    courseId?: number;
  
    title?: string | null;
    subTitle?: string | null;
    notes?: string | null;
    type?: ICertificateMetadata;
    ceu?: number | null;
  }