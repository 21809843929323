/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ILookupCertificateResult { 
    certNumber?: string | null;
    title?: string | null;
    typeId?: number;
    type?: string | null;
    issuedDate?: string;
    expirationDate?: string | null;
    source?: string | null;
    status?: string | null;
    ceu?: string | null;
    sourceXRef?: string | null;
}

