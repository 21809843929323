import { Component, OnInit, Inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
/** home component*/
export class HomeComponent implements OnInit  {

  constructor(private router: Router) {

  }

  ngOnInit(): void {

  }

}
