/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CertificateOptions } from './certificateOptions';


export interface AppDetails { 
    countByStatus?: { [key: string]: number; } | null;
    totalCertificates?: number;
    totalUserInfoSnapshot?: number;
    totalCertificateTypes?: number;
    totalCourses?: number;
    options?: CertificateOptions;
}

