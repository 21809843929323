<header>
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-dark bg-dark border-bottom box-shadow mb-3">
    <div class="container">
      <a class="navbar-brand" [routerLink]="['/app']"><i aria-hidden="true" class="fa fa-certificate"></i> Certificate Admin</a>

      <div class="navbar-collapse collapse d-sm-inline-flex justify-content-end">

        <ul class="navbar-nav flex-grow" *ngFor="let menu of menus?.mainMenu">
          <li class="nav-item" ngbDropdown *ngIf="showMenu">
            <a class="nav-link" style="cursor: pointer" ngbDropdownToggle id="metaDropdown" role="button">
              {{ menu.name }}
            </a>
            <div ngbDropdownMenu aria-labelledby="metaDropdown" class="dropdown-menu dropdown-menu-right">
              <ng-container  *ngFor="let sub of menu.subMenu">
                <a ngbDropdownItem class="" [routerLink]="[sub.route]" *ngIf="showMenu">{{ sub.name }}</a>
              </ng-container>
            </div>
          </li>
        </ul>

        <ul class="navbar-nav flex-grow">
          <li class="nav-item">
            <a (click)="open(auth)" class="nav-link text-light" href="javascript:void(0);">
              <span class="fa-stack fa-md">
                <i aria-hidden="true" class="fa fa-circle fa-stack-2x" [ngClass]="setKeyStyle()"></i>
                <i aria-hidden="true" class="fa fa-key fa-stack-1x fa-inverse"></i>
              </span>
            </a>
          </li>

          <li class="nav-item" ngbDropdown>
            <a class="nav-link text-light" style="cursor: pointer" ngbDropdownToggle id="settingsDropdown" role="button">
              <i aria-hidden="true" class="fa fa-cog fa-2x"></i>
            </a>
            <div ngbDropdownMenu aria-labelledby="settingsDropdown" class="dropdown-menu dropdown-menu-right">
              <a ngbDropdownItem class="" [routerLink]="['/app/stats']" *ngIf="showMenu">App Settings &amp; Stats</a>
              <a ngbDropdownItem class="" [routerLink]="['/app/admin/key-manager']" *ngIf="showMenu">API Key Manager</a>
              <div class="dropdown-divider"></div>
              <a ngbDropdownItem href="/swagger" target="_blank" class="d-flex justify-content-between align-items-center" ngbTooltip="Opens in new tab">Swagger API <i aria-hidden="true" class="fa fa-external-link"></i></a>
              <a ngbDropdownItem href="https://wiki.ascendlearning.com/display/~richard.zerdelian/Certificate+Service+Run+Book"
                target="_blank" class="d-flex justify-content-between align-items-center"
                rel="noopener noreferrer"
                ngbTooltip="Opens in new tab">Wiki Doc <i aria-hidden="true" class="fa fa-file-text-o"></i></a>
              <div class="dropdown-divider"></div>
              <h6 class="dropdown-header">THEMES</h6>

              <a *ngFor="let kv of themeDic" ngbDropdownItem (click)="loadStyle(kv.key)" href="javascript:void(0);"
                 style="cursor: pointer" class="d-flex justify-content-between align-items-center">{{kv.value}} <i aria-hidden="true" class="fa fa-check" *ngIf="currentStyle==kv.key"></i></a>

            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>

<ng-template #auth let-modal>
  <div class="modal-header bg-dark text-light">
    <h4 class="modal-title" id="addAuthKey">Set Auth Key for Access</h4>
    <a class="close btn" aria-label="Close" (click)="closeModal(modal)">
      <span aria-hidden="true">&times;</span>
    </a>
  </div>
  <div class="modal-body">
    <form [formGroup]="editForm" (ngSubmit)="editForm.valid && onSubmit()">

      <p>Enter the auth/api key to view data.</p>

      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text"><i aria-hidden="true" class="fa fa-key"></i></span>
        </div>
        <input type="password" class="form-control" id="authkey" formControlName="authkey" name="authkey" placeholder="Auth Key" aria-label="Auth Key" aria-describedby="button-addon" autocomplete="off">
        <div class="input-group-append">
          <button class="btn btn-outline-success" type="submit">Set</button>
          <button class="btn btn-outline-danger" type="button" (click)="onClear()">Clear</button>
        </div>
      </div>

    </form>
    <div style="text-align:center;">
      <a [routerLink]="['/app/login']" *ngIf="isBULoginReq()" (click)="closeModal(modal)">Login to UA login</a>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="closeModal(modal)">Close</button>
  </div>
</ng-template>

