/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CertificateStatusCodeEnum } from './certificateStatusCodeEnum';


/**
 * userSearch object - at least one item must be populated for search
 */
export interface PublicSearch { 
    /**
     * UA ID for user
     */
    userId?: number;
    /**
     * certificate number
     */
    certNumber?: string | null;
    /**
     * only used with last name if available
     */
    firstName?: string | null;
    /**
     * last name of user
     */
    lastName?: string | null;
    status?: CertificateStatusCodeEnum;
}

