/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApiKey } from './apiKey';
import { MetaTypeRule } from './metaTypeRule';
import { CourseMeta } from './courseMeta';
import { Metadata } from './metadata';


export interface DataCopyModel { 
    course?: CourseMeta;
    type?: Metadata;
    rules?: Array<MetaTypeRule> | null;
    templatesIds?: Array<number> | null;
    keys?: Array<ApiKey> | null;
}

