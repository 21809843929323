/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BadgeVendorApi } from './badgeVendorApi';
import { UserAuthoritySettings } from './userAuthoritySettings';
import { KafkaOptions } from './kafkaOptions';


export interface BadgingOptionsModel { 
    useVault?: boolean;
    applicationName?: string | null;
    authorizationKeys?: { [key: string]: string; } | null;
    kafka?: KafkaOptions;
    renewSource?: string | null;
    enableAuditEventsToDatabase?: boolean;
    badgeVendorApi?: BadgeVendorApi;
    userAuthoritySettings?: UserAuthoritySettings;
}

