import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthService {

  private currentKey = new BehaviorSubject(null);

  //subscribe
  apiKey = this.currentKey.asObservable();

  constructor() {

    var key = this.getKey();
    if (key!= null) {
      this.currentKey.next(key);
    }
  }

  hasApiKey(): boolean {
    var key = this.currentKey.getValue();
    //console.log('hasApiKey ' + key);
    return (key);
  }

  getApiKey(): string {
    //console.log('getting key');
    return this.currentKey.getValue();
  }

  setApiKey(val: string): void {
    console.log('setting key');
    this.currentKey.next(val);

    if (val) {
      //set key local storage in case of reload to persist
      this.setKey(this.currentKey.getValue());
    }
    else {
      this.setKey(null);
    }
  }

  private getKey() : any {
    return window.sessionStorage.getItem('apiKey');
  }

  private setKey(key: string) {
    if (key) {
      window.sessionStorage.setItem('apiKey', key);
    }
    else {
      window.sessionStorage.removeItem('apiKey');
      window.sessionStorage.removeItem('UAUsername');
    }
  }


}
