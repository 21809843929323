import { LowerCasePipe } from '@angular/common';
import { Component, Input, OnInit, Output, PipeTransform } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseMetasService } from '@api/courseMetas.service';
import { ToastNotificationService } from '@core/toast-notification.service';
import { CourseMeta } from '@model/models';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CourseMetaFlatten } from 'src/app/model_custom/courseMetaFlatten';

@Component({
  selector: 'app-meta-course-selector',
  templateUrl: './meta-course-selector.component.html',
  styleUrls: ['./meta-course-selector.component.scss']
})
export class MetaCourseSelectorComponent implements OnInit {

  /** courseId pre-selected id */
  @Input() public lmsItemId: number;

  subs: Subscription[] = [];
  public courses: CourseMetaFlatten[] = [];
  public filteredCourses: CourseMetaFlatten[] = [];

  private currentCourse = new BehaviorSubject<CourseMetaFlatten>(null);
  @Output() selected = this.currentCourse.asObservable();

  filterForm: FormGroup;

  constructor(private cmApi: CourseMetasService,
    private activeRte: ActivatedRoute,
    private toastService: ToastNotificationService,
    private router: Router,
    private pipe: LowerCasePipe,
    private formBuilder: FormBuilder) {
    this.filterForm = this.formBuilder.group({
      filter: ['']
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    console.log('meta-course-selector after view init');
    setTimeout(() => {
      this.cmApi.apiV2CoursemetasGet()
        .subscribe({
          next: res => {
            if (!res.error) {
              res.data?.filter(x => x.isActive == true)?.forEach(a => {
                if (a.earn) {
                  //this.courses.push(a);
                  a.lmsItems.forEach(l => {
                    var c: CourseMetaFlatten = { ...a };
                    c.lmsItem = l;
                    this.courses.push(c);
                  })
                }
              });
              this.filteredCourses = this.courses?.filter(x => x.isActive == true);
              if (this.currentCourse == null && this.lmsItemId > 0)
                this.currentCourse.next(this.courses.find(a => a.lmsItem.id == this.lmsItemId));

              this.filterForm.controls['filter'].valueChanges
                .pipe(startWith(''), map(text => this.search(text, this.pipe)))
                .subscribe(results => {
                  this.filteredCourses = results;
                  // this.collectionSize = results?.length;
                  // this.page = 1;
                });
            }
            else
              this.toastService.showResult(res.error, res.messages);
          },
          error: err => this.toastService.showResult(true, err.error.messages)
        });
    });
  }

  ngOnDestroy() {
    for (let s of this.subs) {
      s.unsubscribe();
    }
  }

  setSelectedCourse(lmsItemId: number) {
    this.lmsItemId = lmsItemId;
    console.log(lmsItemId + ' selected');
    this.currentCourse.next(this.courses.find(a => a.lmsItem.id == this.lmsItemId));
  }

  search(text: string, pipe: PipeTransform): CourseMetaFlatten[] {
    // console.log(text);
    // console.log(this.courses?.length);
    return this.courses?.filter(cm => {
      const term = text.toLowerCase();
      //return pipe.transform(cm.name).includes(term)
      return cm.name.toLowerCase().includes(term)
        || pipe.transform(cm.lmsItem.activityName)?.includes(term)
        || pipe.transform(cm.lmsItem.courseId)?.includes(term)
        || pipe.transform(cm.lmsItem.activityId)?.includes(term)
        || pipe.transform(cm.title)?.includes(term)
        || pipe.transform(cm.subTitle)?.includes(term)
        || pipe.transform(this.getLmsName(cm.lmsItem.source))?.includes(term)
        || pipe.transform(cm.notes)?.includes(term)
        || pipe.transform(cm.type.type)?.includes(term);
    });
  }

  getLmsName(val): string {
    if (val == 0)
      return "Moodle";
    else if (val == 1)
      return "Crowd Wisdom";
    else
      return "Other"
  }

}

