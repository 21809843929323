import { Component, Input, OnInit } from '@angular/core';
import { CertificateStatusCodeEnum } from '@model/models';
@Component({
  //selector: 'app-certificate-status-badge',
  selector: 'StatusBadge',
  templateUrl: './certificate-status-badge.component.html',
  styleUrls: ['./certificate-status-badge.component.scss']
})
export class CertificateStatusBadgeComponent implements OnInit {

  @Input() public status: CertificateStatusCodeEnum;
  
  constructor() { }

  ngOnInit(): void {
  }

  getBadgeStyle(): string {
    switch(this.status) {
      case CertificateStatusCodeEnum.Pending: 
        return "badge badge-warning text-dark";
      case CertificateStatusCodeEnum.Valid:
        return "badge badge-success";
      case CertificateStatusCodeEnum.Deactivated:
      case CertificateStatusCodeEnum.Cancelled:
        return "badge badge-secondary";
      default:
        return "badge badge-danger";
    }
  }
}
