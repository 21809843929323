/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { BooleanIResult } from '../model/models';
import { GuidStringDictionaryIResult } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class UserMigrationService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Migrates user info for certificate to local database
     * @param rowId 
     * @param uaId 
     * @param certNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2UsersUaIdCertNumberRowIdPost(rowId: string, uaId: number, certNumber: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanIResult>;
    public apiV2UsersUaIdCertNumberRowIdPost(rowId: string, uaId: number, certNumber: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanIResult>>;
    public apiV2UsersUaIdCertNumberRowIdPost(rowId: string, uaId: number, certNumber: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanIResult>>;
    public apiV2UsersUaIdCertNumberRowIdPost(rowId: string, uaId: number, certNumber: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (rowId === null || rowId === undefined) {
            throw new Error('Required parameter rowId was null or undefined when calling apiV2UsersUaIdCertNumberRowIdPost.');
        }
        if (uaId === null || uaId === undefined) {
            throw new Error('Required parameter uaId was null or undefined when calling apiV2UsersUaIdCertNumberRowIdPost.');
        }
        if (certNumber === null || certNumber === undefined) {
            throw new Error('Required parameter certNumber was null or undefined when calling apiV2UsersUaIdCertNumberRowIdPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanIResult>(`${this.configuration.basePath}/api/v2/users/${encodeURIComponent(String(uaId))}/${encodeURIComponent(String(certNumber))}/${encodeURIComponent(String(rowId))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Migrates user info for certificate to local database
     * @param rowId 
     * @param uaId 
     * @param certNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2UsersUaIdCertNumberRowIdRefreshPost(rowId: string, uaId: number, certNumber: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanIResult>;
    public apiV2UsersUaIdCertNumberRowIdRefreshPost(rowId: string, uaId: number, certNumber: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanIResult>>;
    public apiV2UsersUaIdCertNumberRowIdRefreshPost(rowId: string, uaId: number, certNumber: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanIResult>>;
    public apiV2UsersUaIdCertNumberRowIdRefreshPost(rowId: string, uaId: number, certNumber: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (rowId === null || rowId === undefined) {
            throw new Error('Required parameter rowId was null or undefined when calling apiV2UsersUaIdCertNumberRowIdRefreshPost.');
        }
        if (uaId === null || uaId === undefined) {
            throw new Error('Required parameter uaId was null or undefined when calling apiV2UsersUaIdCertNumberRowIdRefreshPost.');
        }
        if (certNumber === null || certNumber === undefined) {
            throw new Error('Required parameter certNumber was null or undefined when calling apiV2UsersUaIdCertNumberRowIdRefreshPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanIResult>(`${this.configuration.basePath}/api/v2/users/${encodeURIComponent(String(uaId))}/${encodeURIComponent(String(certNumber))}/${encodeURIComponent(String(rowId))}/refresh`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a dictionary with the row id and cert number of rows that have been migrated to the local database
     * @param uaId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2UsersUaIdGet(uaId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GuidStringDictionaryIResult>;
    public apiV2UsersUaIdGet(uaId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GuidStringDictionaryIResult>>;
    public apiV2UsersUaIdGet(uaId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GuidStringDictionaryIResult>>;
    public apiV2UsersUaIdGet(uaId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uaId === null || uaId === undefined) {
            throw new Error('Required parameter uaId was null or undefined when calling apiV2UsersUaIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<GuidStringDictionaryIResult>(`${this.configuration.basePath}/api/v2/users/${encodeURIComponent(String(uaId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Migrates all records for the user to the local database if the user does not exist
     * @param uaId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2UsersUaIdPost(uaId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanIResult>;
    public apiV2UsersUaIdPost(uaId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanIResult>>;
    public apiV2UsersUaIdPost(uaId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanIResult>>;
    public apiV2UsersUaIdPost(uaId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uaId === null || uaId === undefined) {
            throw new Error('Required parameter uaId was null or undefined when calling apiV2UsersUaIdPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanIResult>(`${this.configuration.basePath}/api/v2/users/${encodeURIComponent(String(uaId))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Migrates all records for the user to the local database, existing entries will be overwritten
     * @param uaId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2UsersUaIdRefreshPost(uaId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanIResult>;
    public apiV2UsersUaIdRefreshPost(uaId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanIResult>>;
    public apiV2UsersUaIdRefreshPost(uaId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanIResult>>;
    public apiV2UsersUaIdRefreshPost(uaId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uaId === null || uaId === undefined) {
            throw new Error('Required parameter uaId was null or undefined when calling apiV2UsersUaIdRefreshPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanIResult>(`${this.configuration.basePath}/api/v2/users/${encodeURIComponent(String(uaId))}/refresh`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
