/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Certificate type details
 */
export interface Metadata { 
    id?: number;
    /**
     * Name of type
     */
    type: string;
    /**
     * Duration certificate is valid for in months
     */
    duration?: number | null;
    /**
     * Template id used for rendering the printable certificate
     */
    renderTemplateId?: string | null;
    additionalInfo?: string | null;
    templateId?: number | null;
    durationType?: string | null;
    isActive?: boolean;
}

