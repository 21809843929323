export * from './apiKeyManager.service';
import { ApiKeyManagerService } from './apiKeyManager.service';
export * from './certificateTypes.service';
import { CertificateTypesService } from './certificateTypes.service';
export * from './certificatesV2.service';
import { CertificatesV2Service } from './certificatesV2.service';
export * from './courseMetas.service';
import { CourseMetasService } from './courseMetas.service';
export * from './dataTransfer.service';
import { DataTransferService } from './dataTransfer.service';
export * from './externalBadging.service';
import { ExternalBadgingService } from './externalBadging.service';
export * from './public.service';
import { PublicService } from './public.service';
export * from './rulesEngine.service';
import { RulesEngineService } from './rulesEngine.service';
export * from './stats.service';
import { StatsService } from './stats.service';
export * from './templates.service';
import { TemplatesService } from './templates.service';
export * from './uALogin.service';
import { UALoginService } from './uALogin.service';
export * from './userMigration.service';
import { UserMigrationService } from './userMigration.service';
export const APIS = [ApiKeyManagerService, CertificateTypesService, CertificatesV2Service, CourseMetasService, DataTransferService, ExternalBadgingService, PublicService, RulesEngineService, StatsService, TemplatesService, UALoginService, UserMigrationService];
