<div class="card bg-info border-info">
    <h4 class="card-title p-2 mb-0">Courses</h4>
    <div class="list-group list-group-flush scroller" style="max-height: 500px; overflow-y: scroll; overflow-x: no-display" id="courseScroll">
      <div class="list-group-item">
        <div [formGroup]="filterForm">
          <div class="form-group">
            <input class="form-control" type="text" formControlName="filter" id="filter" placeholder="filter on course id, name, title, or type" autocomplete="off" />
          </div>
        </div>
      </div>
      <div class="list-group-item" *ngFor="let item of filteredCourses" (click)="setSelectedCourse(item.lmsItem.id)"
           [ngClass]="{'active': lmsItemId, 'text-muted bg-light': lmsItemId != item.lmsItem.id }" style="cursor: pointer;">
          
        <span class="overflow-auto">
          <h5 class="mb-1" ngbTooltip="Course name">{{ item.name }} </h5>
        </span>

        <div class="d-flex w-100 justify-content-between mb-2">
          <span class="badge badge-secondary" gbTooltip="LMS"> {{ getLmsName(item.lmsItem.source) }} </span>
          <span class="badge badge-info" ngbTooltip="Course Id in LMS"> {{ item.lmsItem.courseId }} </span>
        </div>

        <div class="d-flex w-100 justify-content-between border-bottom border-info small">
          <span>Activity Name</span>
          <span>{{ item.lmsItem.activityName }}</span>
        </div>
        
        <div class="d-flex w-100 justify-content-between border-bottom border-info small">
          <span>Activity Id</span>
          <span>{{ item.lmsItem.activityId }}</span>
        </div>

        <div class="d-flex w-100 justify-content-between border-bottom border-info small">
          <span>Title</span>
          <span> {{ item.title }} </span>
        </div>

        <div class="d-flex w-100 justify-content-between border-bottom border-info small">
          <span>Type</span>
          <span> {{ item.type.type }} </span>
        </div>
      </div>
    </div>
  </div>