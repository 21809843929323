/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateStatusJob { 
    /**
     * Interval to run tasks in job using the format;   10m, 2h, 1d - 10 minutes, 2 hours, 1 day
     */
    frequency?: string | null;
    bulkUpdateSize?: number;
    enabled?: boolean;
}

