/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AwardDetails } from '../model/models';
import { BackdatedCertificate } from '../model/models';
import { BooleanIResult } from '../model/models';
import { ByteArrayIResult } from '../model/models';
import { CertificateBasic } from '../model/models';
import { GroupedCertificate } from '../model/models';
import { ICertificateIEnumerableIResult } from '../model/models';
import { ICertificateIResult } from '../model/models';
import { ILookupUserResultIEnumerableIResult } from '../model/models';
import { Int32IResult } from '../model/models';
import { RenewCertificate } from '../model/models';
import { StringIApiResult } from '../model/models';
import { UserCertificateIEnumerableIResult } from '../model/models';
import { UserCertificateIResult } from '../model/models';
import { UserSearch } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class CertificatesV2Service {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Award certificates by course
     * @param awardDetails
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CertificatesAwardByCoursePost(awardDetails?: AwardDetails, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<UserCertificateIEnumerableIResult>;
    public apiV2CertificatesAwardByCoursePost(awardDetails?: AwardDetails, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<UserCertificateIEnumerableIResult>>;
    public apiV2CertificatesAwardByCoursePost(awardDetails?: AwardDetails, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<UserCertificateIEnumerableIResult>>;
    public apiV2CertificatesAwardByCoursePost(awardDetails?: AwardDetails, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<UserCertificateIEnumerableIResult>(`${this.configuration.basePath}/api/v2/certificates/awardByCourse`,
            awardDetails,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the certificate for the number specified
     * @param certNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CertificatesCertNumberGet(certNumber: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<UserCertificateIResult>;
    public apiV2CertificatesCertNumberGet(certNumber: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<UserCertificateIResult>>;
    public apiV2CertificatesCertNumberGet(certNumber: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<UserCertificateIResult>>;
    public apiV2CertificatesCertNumberGet(certNumber: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (certNumber === null || certNumber === undefined) {
            throw new Error('Required parameter certNumber was null or undefined when calling apiV2CertificatesCertNumberGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<UserCertificateIResult>(`${this.configuration.basePath}/api/v2/certificates/${encodeURIComponent(String(certNumber))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Resends the certificate for processing
     * @param certNumber
     * @param eventType
     * @param parentCertNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CertificatesCertNumberQueuePost(certNumber: string, eventType?: string, parentCertNumber?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanIResult>;
    public apiV2CertificatesCertNumberQueuePost(certNumber: string, eventType?: string, parentCertNumber?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanIResult>>;
    public apiV2CertificatesCertNumberQueuePost(certNumber: string, eventType?: string, parentCertNumber?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanIResult>>;
    public apiV2CertificatesCertNumberQueuePost(certNumber: string, eventType?: string, parentCertNumber?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (certNumber === null || certNumber === undefined) {
            throw new Error('Required parameter certNumber was null or undefined when calling apiV2CertificatesCertNumberQueuePost.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (eventType !== undefined && eventType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>eventType, 'eventType');
        }
        if (parentCertNumber !== undefined && parentCertNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>parentCertNumber, 'parentCertNumber');
        }

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanIResult>(`${this.configuration.basePath}/api/v2/certificates/${encodeURIComponent(String(certNumber))}/queue`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update certificate, after a successful update a notification is sent to the topic if configured
     * @param certNumber certificate number
     * @param rowId certificate row identifier
     * @param certificateBasic certificate details for update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CertificatesCertNumberRowIdPut(certNumber: string, rowId: string, certificateBasic?: CertificateBasic, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ICertificateIResult>;
    public apiV2CertificatesCertNumberRowIdPut(certNumber: string, rowId: string, certificateBasic?: CertificateBasic, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ICertificateIResult>>;
    public apiV2CertificatesCertNumberRowIdPut(certNumber: string, rowId: string, certificateBasic?: CertificateBasic, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ICertificateIResult>>;
    public apiV2CertificatesCertNumberRowIdPut(certNumber: string, rowId: string, certificateBasic?: CertificateBasic, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (certNumber === null || certNumber === undefined) {
            throw new Error('Required parameter certNumber was null or undefined when calling apiV2CertificatesCertNumberRowIdPut.');
        }
        if (rowId === null || rowId === undefined) {
            throw new Error('Required parameter rowId was null or undefined when calling apiV2CertificatesCertNumberRowIdPut.');
        }

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<ICertificateIResult>(`${this.configuration.basePath}/api/v2/certificates/${encodeURIComponent(String(certNumber))}/${encodeURIComponent(String(rowId))}`,
            certificateBasic,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a backdated certificate with a pre-existing cert number
     * @param backdatedCertificate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CertificatesCreateBackdatedPost(backdatedCertificate?: BackdatedCertificate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ICertificateIResult>;
    public apiV2CertificatesCreateBackdatedPost(backdatedCertificate?: BackdatedCertificate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ICertificateIResult>>;
    public apiV2CertificatesCreateBackdatedPost(backdatedCertificate?: BackdatedCertificate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ICertificateIResult>>;
    public apiV2CertificatesCreateBackdatedPost(backdatedCertificate?: BackdatedCertificate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ICertificateIResult>(`${this.configuration.basePath}/api/v2/certificates/createBackdated`,
            backdatedCertificate,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uaid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CertificatesGroupedUaidGet(uaid: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<GroupedCertificate>>;
    public apiV2CertificatesGroupedUaidGet(uaid: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<GroupedCertificate>>>;
    public apiV2CertificatesGroupedUaidGet(uaid: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<GroupedCertificate>>>;
    public apiV2CertificatesGroupedUaidGet(uaid: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uaid === null || uaid === undefined) {
            throw new Error('Required parameter uaid was null or undefined when calling apiV2CertificatesGroupedUaidGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<GroupedCertificate>>(`${this.configuration.basePath}/api/v2/certificates/grouped/${encodeURIComponent(String(uaid))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Merges certificates for 2 users - certs from source will be added to the destination
     * @param sourceUaId user id to get certificates - certificates will be removed upon success
     * @param destUaId user id to add certificates
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CertificatesMergeSourceUaIdDestUaIdPut(sourceUaId: number, destUaId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Int32IResult>;
    public apiV2CertificatesMergeSourceUaIdDestUaIdPut(sourceUaId: number, destUaId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Int32IResult>>;
    public apiV2CertificatesMergeSourceUaIdDestUaIdPut(sourceUaId: number, destUaId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Int32IResult>>;
    public apiV2CertificatesMergeSourceUaIdDestUaIdPut(sourceUaId: number, destUaId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sourceUaId === null || sourceUaId === undefined) {
            throw new Error('Required parameter sourceUaId was null or undefined when calling apiV2CertificatesMergeSourceUaIdDestUaIdPut.');
        }
        if (destUaId === null || destUaId === undefined) {
            throw new Error('Required parameter destUaId was null or undefined when calling apiV2CertificatesMergeSourceUaIdDestUaIdPut.');
        }

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<Int32IResult>(`${this.configuration.basePath}/api/v2/certificates/merge/${encodeURIComponent(String(sourceUaId))}/${encodeURIComponent(String(destUaId))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uaid
     * @param certNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CertificatesRelatedUaidCertNumberGet(uaid: number, certNumber: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<string>>;
    public apiV2CertificatesRelatedUaidCertNumberGet(uaid: number, certNumber: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<string>>>;
    public apiV2CertificatesRelatedUaidCertNumberGet(uaid: number, certNumber: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<string>>>;
    public apiV2CertificatesRelatedUaidCertNumberGet(uaid: number, certNumber: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uaid === null || uaid === undefined) {
            throw new Error('Required parameter uaid was null or undefined when calling apiV2CertificatesRelatedUaidCertNumberGet.');
        }
        if (certNumber === null || certNumber === undefined) {
            throw new Error('Required parameter certNumber was null or undefined when calling apiV2CertificatesRelatedUaidCertNumberGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<string>>(`${this.configuration.basePath}/api/v2/certificates/related/${encodeURIComponent(String(uaid))}/${encodeURIComponent(String(certNumber))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a byte array to render a printable certificate
     * @param rowId row identifier for certificate
     * @param format format options (i.e.: PDF)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CertificatesRenderRowIdFormatGet(rowId: string, format: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ByteArrayIResult>;
    public apiV2CertificatesRenderRowIdFormatGet(rowId: string, format: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ByteArrayIResult>>;
    public apiV2CertificatesRenderRowIdFormatGet(rowId: string, format: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ByteArrayIResult>>;
    public apiV2CertificatesRenderRowIdFormatGet(rowId: string, format: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (rowId === null || rowId === undefined) {
            throw new Error('Required parameter rowId was null or undefined when calling apiV2CertificatesRenderRowIdFormatGet.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling apiV2CertificatesRenderRowIdFormatGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ByteArrayIResult>(`${this.configuration.basePath}/api/v2/certificates/render/${encodeURIComponent(String(rowId))}/${encodeURIComponent(String(format))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Renew API (For internal brightlink integration service accessible by different Auth Key)
     * @param renewCertificate info for renewal
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CertificatesRenewPost(renewCertificate?: RenewCertificate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ICertificateIResult>;
    public apiV2CertificatesRenewPost(renewCertificate?: RenewCertificate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ICertificateIResult>>;
    public apiV2CertificatesRenewPost(renewCertificate?: RenewCertificate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ICertificateIResult>>;
    public apiV2CertificatesRenewPost(renewCertificate?: RenewCertificate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ICertificateIResult>(`${this.configuration.basePath}/api/v2/certificates/renew`,
            renewCertificate,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uaid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CertificatesRenewablesUaidGet(uaid: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<string>>;
    public apiV2CertificatesRenewablesUaidGet(uaid: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<string>>>;
    public apiV2CertificatesRenewablesUaidGet(uaid: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<string>>>;
    public apiV2CertificatesRenewablesUaidGet(uaid: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uaid === null || uaid === undefined) {
            throw new Error('Required parameter uaid was null or undefined when calling apiV2CertificatesRenewablesUaidGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<string>>(`${this.configuration.basePath}/api/v2/certificates/renewables/${encodeURIComponent(String(uaid))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search for certificates by user or by cert number, searches performed against UA and local database
     * - search by userid: returns user with all certificates associated with user  - search by certNumber: finds user by cert number and returns user with all certificates associated with user  - search by email: returns user with all certificates associated with user  - search by lastname (and firstname): returns users with associated certificates for each user
     * @param userSearch search options - at least one field is required, firstname is only valid if lastname is populated
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CertificatesSearchPost(userSearch?: UserSearch, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ILookupUserResultIEnumerableIResult>;
    public apiV2CertificatesSearchPost(userSearch?: UserSearch, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ILookupUserResultIEnumerableIResult>>;
    public apiV2CertificatesSearchPost(userSearch?: UserSearch, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ILookupUserResultIEnumerableIResult>>;
    public apiV2CertificatesSearchPost(userSearch?: UserSearch, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ILookupUserResultIEnumerableIResult>(`${this.configuration.basePath}/api/v2/certificates/search`,
            userSearch,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a list of certificates belonging to the UA Id
     * @param uaId UserAuthority ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CertificatesUsersUaIdGet(uaId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ICertificateIEnumerableIResult>;
    public apiV2CertificatesUsersUaIdGet(uaId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ICertificateIEnumerableIResult>>;
    public apiV2CertificatesUsersUaIdGet(uaId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ICertificateIEnumerableIResult>>;
    public apiV2CertificatesUsersUaIdGet(uaId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uaId === null || uaId === undefined) {
            throw new Error('Required parameter uaId was null or undefined when calling apiV2CertificatesUsersUaIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ICertificateIEnumerableIResult>(`${this.configuration.basePath}/api/v2/certificates/users/${encodeURIComponent(String(uaId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new certificate for a user
     * @param certificateBasic
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public certificates(certificateBasic?: CertificateBasic, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ICertificateIResult>;
    public certificates(certificateBasic?: CertificateBasic, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ICertificateIResult>>;
    public certificates(certificateBasic?: CertificateBasic, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ICertificateIResult>>;
    public certificates(certificateBasic?: CertificateBasic, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (AuthKey) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["AuthKey"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ICertificateIResult>(`${this.configuration.basePath}/api/v2/certificates`,
            certificateBasic,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
