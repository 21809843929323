/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CertificateStatusCodeEnum } from './certificateStatusCodeEnum';


export interface CertificateBasic { 
    uaId: number;
    certMetadataId?: number;
    type?: string | null;
    certNumber?: string | null;
    certificateStatus?: CertificateStatusCodeEnum;
    title: string;
    source?: string | null;
    sourceXRef?: string | null;
    issuedDate: string;
    expirationDate?: string | null;
    notes?: string | null;
    ceu?: string | null;
    courseMetaId?: number | null;
    lmsCourseId?: string | null;
    lmsSource?: string | null;
    lmsItemId?: number | null;
    graceExpireDays?: number;
}

