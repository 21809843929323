/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TemplateField } from './templateField';


export interface CertificateTemplate { 
    id?: number;
    backgroundString: string;
    tags?: Array<string> | null;
    name?: string | null;
    isActive?: boolean;
    background?: string | null;
    height?: number;
    width?: number;
    fields?: Array<TemplateField> | null;
}

