/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IError } from './iError';


export interface StringIApiResult { 
    status?: number | null;
    errors?: { [key: string]: Array<IError>; } | null;
    /**
     * data returned from request
     */
    data?: string | null;
    error?: boolean;
    messages?: Array<string> | null;
}

