/**
 * Certificate Service
 * API to manage certificates<br /><a href=\'/app\' target=\'_blank\'>Open Admin</a>
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CertificateStatusCodeEnum = 'Valid' | 'Deactivated' | 'Revoked' | 'Expired' | 'Cancelled' | 'Duplicate' | 'Pending';

export const CertificateStatusCodeEnum = {
    Valid: 'Valid' as CertificateStatusCodeEnum,
    Deactivated: 'Deactivated' as CertificateStatusCodeEnum,
    Revoked: 'Revoked' as CertificateStatusCodeEnum,
    Expired: 'Expired' as CertificateStatusCodeEnum,
    Cancelled: 'Cancelled' as CertificateStatusCodeEnum,
    Duplicate: 'Duplicate' as CertificateStatusCodeEnum,
    Pending: 'Pending' as CertificateStatusCodeEnum
};

