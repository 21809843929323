<div class="container">
  <div class="jumbotron">
    <h1>Certificate Admin UI</h1>
    <p>An API key is required. Set the key using the key icon in the top right.</p>
  </div>

  <h3 class="bg-dark text-light p-2">Components</h3>
  <div class="card-columns">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Using this app</h4>
        <p class="card-text">Manage user certificates and the components that make up the certificate.</p>
        <p class="card-text">Notifications when making updates will display in the top left corner of the window with status messages.</p>
        <p class="card-text">This app is using the v2 endpoints.</p>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h4 class="card-title">User Information</h4>
        <p class="card-text">User info is captured locally from UA when the certificate is created.</p>
        <p class="card-text">A temporary job to migrate users can be configured to run in the background.</p>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Certificate Setup</h4>
        <p class="card-text">Add/Edit templates for certificates. Set the background image, position and style text for the end user to receive a PDF.</p>
        <p class="card-text">Add/Edit certificate types. Set the duration, the template for rendering/display and optionally add template overrides.</p>
        <p class="card-text">Add/Edit course to generate certificates and assign CEU's. Courses require a type for certificate rendering.</p>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Award Management</h4>
        <p class="card-text mb-1">Search for users or certificates</p>
        <ul>
          <li>View users' certificates</li>
          <li>Merge certificates</li>
          <li>Resend certificates to kafka topic</li>
          <li>Limited edits for certificate</li>
          <li>Migrate user to local DB</li>
        </ul>

        <p class="card-text">Manually create new or backdated certificates</p>
      </div>
    </div>

    <div class="card bg-dark border-dark">
      <h4 class="card-title text-white mb-0 p-2"><i aria-hidden="true" class="fa fa-gear"></i> App Settings</h4>
      <ul class="list-group list-group-flush">
        <li class="list-group-item ">
          <h5>Stats &amp; Settings</h5>
          <p class="mb-1">View settings for certificate service</p>
          <ul>
            <li>Kafka topics</li>
            <li>Jobs and Job Settings</li>
            <li>Counts and breakdowns for database tables</li>
            <li>Connected services</li>
          </ul>
        </li>
        <li class="list-group-item">
          <h5>API Key Manager</h5>
          <p class="mb-1">Manage access with API Keys.</p>
        </li>
        <li class="list-group-item">
          <h5>Swagger API</h5>
          <p class="mb-1">API documentation, endpoint signatures and model definitions.</p>
        </li>
        <li class="list-group-item">
          <h5>Themes</h5>
          <p class="mb-1">Different themes available including dark mode.</p>
        </li>
      </ul>
    </div>
  </div>



  <h3 class="bg-dark text-light p-2">Workflow</h3>

  <h5 class="p-2 bg-info text-light">Setting up certificates to be awarded</h5>
  <div class="row mb-2">
    <div class="col">
      <div class="card border-primary">
        <h4 class="card-header bg-primary text-white">Template</h4>
        <div class="card-body">
          <p class="card-text">Setup a template to use for the certificate by setting the background image and positioning/styling the award details.</p>
        </div>
      </div>
    </div>
    <div class="col-1 text-success text-center align-middle mt-5"><i aria-hidden="true" class="fa fa-arrow-right fa-3x"></i></div>
    <div class="col">
      <div class="card border-primary">
        <h4 class="card-header bg-primary text-white">Certificate Type</h4>
        <div class="card-body">
          <p class="card-text">Setup a type to define the template and duration for the certificate.</p>
          <p class="card-text">Add/Edit overrides use a different template based on custom conditionals with available fields.</p>
        </div>
      </div>
    </div>
    <div class="col-1 text-success text-center align-middle mt-5"><i aria-hidden="true" class="fa fa-arrow-right fa-3x"></i></div>
    <div class="col">
      <div class="card border-primary">
        <h4 class="card-header bg-primary text-white">Course Details</h4>
        <div class="card-body">
          <p class="card-text">Setup the details used for course completion to apply to certificates awarded; title, CEU, sub-titles</p>
        </div>
      </div>
    </div>
  </div>

  <!--<p class="w-75 m-auto py-3">
    When a message is consumed from kafka, the certificate will be assembled from course and user info and available for validation and rendering.
  </p>-->

  <h5 class="p-2 bg-info text-light">Awarding certificates to users</h5>
  <div class="row mb-2">
    <div class="col">
      <div class="card border-primary">
        <h4 class="card-header bg-secondary text-white">Course Completes</h4>
        <div class="card-body">
          <p class="card-text">Certificate Service listens to a topic for course completion. When receiving the notification, the certificate will be awarded based on the course details. </p>
        </div>
      </div>
    </div>
    <div class="col-1 text-success text-center mt-5"><i aria-hidden="true" class="fa fa-arrow-right fa-3x"></i></div>
    <div class="col">
      <div class="card border-info">
        <h4 class="card-header bg-info text-white">Award Certificate</h4>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">Lookup course/activity for LMS</li>
          <li class="list-group-item">Lookup user info from UA</li>
          <li class="list-group-item">Capture award details and user info</li>
          <li class="list-group-item">Send notification of certificate creation</li>
        </ul>
      </div>
    </div>
    <div class="col-1 text-success text-center mt-5"><i aria-hidden="true" class="fa fa-arrow-right fa-3x"></i></div>
    <div class="col">
      <div class="card border-success">
        <h4 class="card-header bg-success text-white">Certificates Awarded</h4>
        <div class="card-body">
          <p class="card-text">Certificates can be created, validated, and updated from the admin tool or thru the API.</p>
        </div>
      </div>
    </div>
  </div>
