<div class="container">

  <!--<h1>Settings and Stats</h1>-->
  <div class="float-right">
    <button type="button" (click)="loadData()" class="btn btn-outline-primary">Refresh Data <i aria-hidden="true"
        class="fa fa-refresh"></i></button>
  </div>

  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink class="h4">Settings &amp; Stats</a>
      <ng-template ngbNavContent>

        <div class="card-deck mb-4">
          <div class="card">
            <h4 class="card-title p-2">
              Table Counts
            </h4>
            <ul class="list-group list-group-flush">
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Total Certificates
                <span>{{ stats?.totalCertificates }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Total Certificate Types
                <span>{{ stats?.totalCertificateTypes }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Total UserInfoSnapshot
                <span>{{ stats?.totalUserInfoSnapshot }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Total Courses
                <span>{{ stats?.totalCourses }}</span>
              </li>
            </ul>
          </div>

          <div class="card">
            <!--<div class="card-header bg-dark text-light">
        Certificate By Status
      </div>-->
            <h4 class="card-title p-2">Certificate By Status</h4>
            <ul class="list-group list-group-flush">
              <li class="list-group-item d-flex justify-content-between align-items-center"
                *ngFor="let item of stats?.countByStatus | keyvalue">
                {{ item.key }}
                <span>{{ item.value }}</span>
              </li>
            </ul>
          </div>

          <div class="card">
            <h4 class="card-title p-2">
              Jobs
            </h4>
            <ng-template #jobDisabled>
              <div class="alert alert-warning">JOB IS DISABLED</div>
            </ng-template>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="card-title">Update Status Job</h5>
                  <span class="badge"
                    [ngClass]="{'badge-success': jobs?.updateStatus?.enabled, 'badge-warning': !jobs?.updateStatus?.enabled }">{{
                    jobs?.updateStatus?.frequency }}</span>
                </div>
                <div *ngIf="jobs?.updateStatus?.enabled; else jobDisabled" class="text-success">
                  Updates {{ jobs?.updateStatus?.bulkUpdateSize }} records every {{ jobs?.updateStatus?.frequency }}.
                </div>
              </li>
              <li class="list-group-item">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="card-title">Migrate To UserSnapshot Table</h5>
                  <span class="badge"
                    [ngClass]="{'badge-success': jobs?.migrateToUserSnapshot?.enabled, 'badge-warning': !jobs?.migrateToUserSnapshot?.enabled }">{{
                    jobs?.migrateToUserSnapshot?.frequency }}</span>
                </div>
                <div *ngIf="jobs?.migrateToUserSnapshot?.enabled; else jobDisabled" class="text-success">
                  Updates {{ jobs?.migrateToUserSnapshot?.bulkUpdateSize }} users every {{
                  jobs?.migrateToUserSnapshot?.frequency }}.
                </div>
              </li>
              <!-- <li class="list-group-item">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="card-title">Auth Key Updater</h5>
                  <span class="badge" [ngClass]="{'badge-success': jobs?.authKeyChecker?.enabled, 'badge-warning': !jobs?.authKeyChecker?.enabled }">{{ jobs?.authKeyChecker?.frequency }}</span>
                </div>
                <div *ngIf="jobs?.authKeyChecker?.enabled; else jobDisabled" class="text-success">
                  AuthKey checks runs every {{ jobs?.authKeyChecker?.frequency }}.
                </div>
              </li> -->
            </ul>
          </div>

        </div>

        <div class="row">
          <div class="col">
            <ul class="list-group">
              <li class="list-group-item list-group-item-heading">
                <h4 class="card-title">
                  Kafka Topics
                </h4>
              </li>

              <ng-container *ngFor="let item of stats?.options.kafka.topics | keyvalue">
                <li class="list-group-item" *ngIf="item.value?.name">

                  <div class="d-flex justify-content-between align-items-center">
                    <h5 class="card-title">{{ item.key }}</h5>
                    <span class="badge"
                      [ngClass]="{'badge-success': item.value.type=='Publisher', 'badge-primary': item.value.type=='Consumer' }">{{
                      item.value.type }}</span>
                  </div>
                  {{ item.value.name }}
                </li>
              </ng-container>

              <!-- <li class="list-group-item" *ngFor="let item of stats?.options.kafka.topics | keyvalue">

                  <div class="d-flex justify-content-between align-items-center">
                    <h5 class="card-title">{{ item.key }}</h5>
                    <span class="badge" [ngClass]="{'badge-success': item.value.type=='Publisher', 'badge-primary': item.value.type=='Consumer' }">{{ item.value.type }}</span>
                  </div>
                  {{ item.value.name }}


              </li> -->
            </ul>

          </div>
          <div class="col">
            <ul class="list-group">
              <li class="list-group-item list-group-item-heading">
                <h4 class="card-title">Connected Services</h4>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                User Authority
                <span>{{ stats?.options.ua.host }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center"
                *ngIf="stats?.options.docGen.host">
                Doc Gen - ext
                <span>{{ stats?.options.docGen.host }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Doc Gen - Template Builder
                <span>{{ stats?.options.docGen.useInternal }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <span>
                  Rules Engine API
                  <span class="badge"
                    [ngClass]="{'badge-success': stats?.options.rulesEngine.apiHook.enabled, 'badge-danger': !stats?.options.kafka.useKafka }">
                    [Enabled={{ stats?.options.rulesEngine.apiHook.enabled }}]
                  </span>
                </span>
                <span>{{ stats?.options.rulesEngine.apiHook.host }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <span>
                  Kafka
                  <span class="badge"
                    [ngClass]="{'badge-success': stats?.options.kafka.useKafka, 'badge-danger': !stats?.options.kafka.useKafka }">
                    [Enabled={{ stats?.options.kafka.useKafka }}]
                  </span>
                </span>
                <span>{{ stats?.options.kafka.kafkaEndpoint }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <span>
                  Badging Service
                  <span class="badge"
                    [ngClass]="{'badge-success': stats?.options.badgingOptions.enabled, 'badge-danger': !stats?.options.badgingOptions.enabled }">
                    [Enabled={{ stats?.options.badgingOptions.enabled }}]
                  </span>
                </span>
                <span>{{ stats?.options.badgingOptions.host }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <span>
                  Brightlink Integration
                  <span class="badge"
                    [ngClass]="{'badge-success': stats?.options.brightlinkIntegration.enabled, 'badge-danger': !stats?.options.brightlinkIntegration.enabled }">
                    [Enabled={{ stats?.options.brightlinkIntegration.enabled }}]
                  </span>
                </span>
                <span>{{ stats?.options.brightlinkIntegration.host }}</span>
              </li>
            </ul>
          </div>
        </div>

      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink class="h4">Audit Events</a>
      <ng-template ngbNavContent>
        <p>Last 100 audit events with adds and updates</p>

        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Date/Time</th>
              <th scope="col">API Key</th>
              <th scope="col">Message</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let event of auditEvents">
              <td>{{ event.date }}</td>
              <td>

                {{ event.apiKeyName }}
              </td>
              <td>
                <div class="d-flex justify-content-between">
                  <div>
                    {{ event.message }}
                  </div>
                  <div *ngIf="event.item && event.itemId">
                    {{ event.item }}: {{ event.itemId }}
                  </div>
                </div>

              </td>

            </tr>
          </tbody>
        </table>
      </ng-template>
    </li>
    <li [ngbNavItem]="3" *ngIf="stats?.options?.badgingOptions?.enabled">
      <a ngbNavLink class="h4">Badging Audit Events</a>
      <ng-template ngbNavContent>
        <p>Last 100 audit events with adds and updates</p>

        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Date/Time</th>
              <th scope="col">API Key</th>
              <th scope="col">Message</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let event of badgingAuditEvents">
              <td>{{ event.date }}</td>
              <td>

                {{ event.apiKeyName }}
              </td>
              <td>
                <div class="d-flex justify-content-between">
                  <div>
                    {{ event.message }}
                  </div>
                  <div *ngIf="event.item && event.itemId">
                    {{ event.item }}: {{ event.itemId }}
                  </div>
                </div>

              </td>

            </tr>
          </tbody>
        </table>
      </ng-template>
    </li>
    <li [ngbNavItem]="4" *ngIf="stats?.options?.badgingOptions?.enabled">
      <a ngbNavLink class="h4">Badging Settings &amp; Stats</a>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col">
            <ul class="list-group">
              <li class="list-group-item list-group-item-heading">
                <h4 class="card-title">
                  Table Counts
                </h4>
              </li>
              <li class="list-group-item">
                <div class="d-flex justify-content-between align-items-center">
                  <span class="card-title">Total Credentials Associated</span>
                  <span>{{ badgingStats?.totalExternalCredentialsAssociated }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="d-flex justify-content-between align-items-center">
                  <span class="card-title">Total Credentials Awarded  </span>
                  <span>{{ badgingStats?.totalExternalCredentialsAwarded }}</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="col">
            <ul class="list-group">
              <li class="list-group-item list-group-item-heading">
                <h4 class="card-title">
                  Kafka Topics
                </h4>
              </li>
              <ng-container *ngFor="let item of badgingStats?.options.kafka.topics | keyvalue">
                <li class="list-group-item" *ngIf="item.value?.name">

                  <div class="d-flex justify-content-between align-items-center">
                    <h5 class="card-title">{{ item.key }}</h5>
                    <span class="badge"
                      [ngClass]="{'badge-success': item.value.type=='Publisher', 'badge-primary': item.value.type=='Consumer' }">{{
                      item.value.type }}</span>
                  </div>
                  {{ item.value.name }}
                </li>
              </ng-container>
            </ul>
          </div>
          <div class="col">
            <ul class="list-group">
              <li class="list-group-item list-group-item-heading">
                <h4 class="card-title">Connected Services</h4>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <span>
                  Kafka
                  <span class="badge"
                    [ngClass]="{'badge-success': badgingStats?.options.kafka.useKafka, 'badge-danger': !badgingStats?.options.kafka.useKafka }">
                    [Enabled={{ badgingStats?.options.kafka.useKafka }}]
                  </span>
                </span>
                <span>{{ badgingStats?.options.kafka.kafkaEndpoint }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <span>
                  Badging Vender API
                  <span class="badge"
                    [ngClass]="{'badge-success': badgingStats?.options?.badgeVendorApi?.host, 'badge-danger': !badgingStats?.options?.badgeVendorApi?.host }">
                    [Enabled={{ badgingStats?.options?.badgeVendorApi?.host ? true : false }}]
                  </span>
                </span>
                <span>{{ badgingStats?.options?.badgeVendorApi?.host }}</span>
              </li>
            </ul>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>


</div>
