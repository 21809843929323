import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Cache } from './cache.service';
import { CacheEntry, MAX_CACHE_AGE } from './cache-entry';

@Injectable()
export class CacheMapService implements Cache  {
    cacheMap = new Map<string, CacheEntry>();
    get(req: HttpRequest<any>): HttpResponse<any> | null {
        const entry = this.cacheMap.get(req.urlWithParams);
        if (!entry) {
            return null;
        }
        const isExpired = (Date.now() - entry.entryTime) > MAX_CACHE_AGE;

        if (!isExpired) { console.log('returned from cache: ' + req.urlWithParams); }

        return isExpired ? null : entry.response;
    }
    put(req: HttpRequest<any>, res: HttpResponse<any>): void {
      if(!req.urlWithParams.includes('/api/v2/types/')) {
        const entry: CacheEntry = { url: req.urlWithParams, response: res, entryTime: Date.now() };
        this.cacheMap.set(req.urlWithParams, entry);
        console.log('added to cache: ' + req.urlWithParams);
        this.deleteExpiredCache();
      }
    }

    invalidateCache(): void {
        this.cacheMap.clear();
        console.log('clear cache');
      }

    private deleteExpiredCache() {
        this.cacheMap.forEach(entry => {
            if ((Date.now() - entry.entryTime) > MAX_CACHE_AGE) {
                this.cacheMap.delete(entry.url);
                console.log('removed from cache: ' + entry.url);
            }
        })
    }
}
