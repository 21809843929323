
<div class="card bg-info border-info">
  <h4 class="card-title p-2 mb-0">Templates</h4>
  <div class="list-group list-group-flush" style="max-height: 500px; overflow-y: scroll; overflow-x: no-display" id="typeScroll">
    <div class="list-group-item">
      <form [formGroup]="filterForm">
        <div class="form-group">
          <input class="form-control" type="text" formControlName="filter" id="filter" placeholder="filter on course id, name, title, or type" autocomplete="off" />
        </div>
      </form>
    </div>
    <div class="list-group-item" *ngFor="let item of templateColl" (click)="setSelectedTemplate(item.id)"
         [ngClass]="{'active': templateId, 'text-muted bg-light': templateId != item.id }" style="cursor: pointer;">
      
      <div class="d-flex w-100 justify-content-between">
        <span class="overflow-auto">
          <h5 class="mb-1">{{ item.name }} </h5>
          <small>{{ item.tags.toString() }}</small>
        </span>
        <img [src]="getImageUrl(item.id)" width="150px" />
      </div>
    </div>
  </div>
</div>
